import styles from "styles/application.module.scss";

import { Grid, Typography } from "@mui/material";
import { DateInput } from "components/DateInput";
import { FormValueDisplay } from "components/FormValueDisplay";
import { DateDisplay, fullWidth, getDateFrom, IOfferInfo, oneThirdWidth } from "library";
import { useEffect, useState } from "react";
import { Actions } from "./Actions";

type OfferDetailProps = {
    value: IOfferInfo;
    onChange: (value: IOfferInfo) => Promise<void>;
}

const OfferDetail = ({ value, onChange }: OfferDetailProps) => {
    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editValue, setEditValue] = useState(value);

    useEffect(() => {
        setEditValue(value);
    }, [value]);

    const set = (v: Partial<IOfferInfo>) => {
        setEditValue(current => ({ ...current, ...v }));
    }

    const onInnerCancel = () => {
        setEditValue(value);
        setEditMode(false);
    };

    const onSave = async () => {
        setEditValue(editValue);
        setProcessing(true);
        onChange(editValue)
            .then(() => setEditMode(false))
            .finally(() => setProcessing(false));
    };

    return (
        <Grid container className={styles.offerDetailContainer}>
            <Grid item {...fullWidth}>
                <Typography variant={"h3"} className={styles.offerLabel}>
                    Offer
                    <Actions processing={processing}
                        editMode={editMode}
                        onEdit={() => setEditMode(true)}
                        onSave={onSave}
                        onCancel={onInnerCancel} />
                </Typography>
            </Grid>
            <Grid item {...oneThirdWidth} className={styles.formDetail}>
                <FormValueDisplay label={"Offer Date"} value=
                    {!editMode
                        ? DateDisplay.Standard(value.onOfferDate)
                        : <DateInput value={editValue.onOfferDate} disabled={processing} onChange={v => set({ onOfferDate: getDateFrom(v) })} />
                    } />
            </Grid>
            <Grid item {...oneThirdWidth} className={styles.formDetail}>
                <FormValueDisplay label={"Offer Due Date"} value=
                    {!editMode
                        ? DateDisplay.Standard(value.offerAcceptanceDueDate)
                        : <DateInput value={editValue.offerAcceptanceDueDate} disabled={processing} onChange={v => set({ offerAcceptanceDueDate: v })} />
                    } />
            </Grid>
            <Grid item {...oneThirdWidth} className={styles.formDetail}>
                <FormValueDisplay label={"Acceptance Date"} value= {DateDisplay.Standard(value.offerAcceptDeclineDate)} />
            </Grid>
        </Grid>
    );
};

export {
    OfferDetail
};

