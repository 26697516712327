import styles from "styles/RateForm.module.scss";

import {Alert, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {Edit, TextArea} from "components";
import {downloadFileBlob, FileInfo, FileStatus, hasError, openFileBlob, PaymentFile, PaymentFileDocType} from "library";
import {IconCheck, IconCircleX} from "@tabler/icons";
import React, {useCallback, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {DocumentsCard} from "components/DocumentsCard";
import {useDispatch} from "store";
import {addDoc, removeDoc, updatePaymentFile, viewOrDownloadFile} from "store/slices/paymentFiles";

type DisbursementsPayFileProps = {}

const DisbursementsPayFile = (props: DisbursementsPayFileProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {state: file} = useLocation();
	const [state, setState] = useState(new PaymentFile(file));
	const [processing, setProcessing] = useState(false);

	const isReadOnlyStatus = [FileStatus.Cancelled, FileStatus.Closed, FileStatus.Rejected].includes((file as PaymentFile)?.status);

	const set = (value: Partial<PaymentFile>) => setState(s => new PaymentFile({...s, ...value}));
	const setErrorFiles = (value: FileInfo[], adding = true) => setState(s => new PaymentFile({
		...s,
		errorFiles: adding
			? [...s.errorFiles.map(f => value.find(inner => inner.equivalentTo(f)) ?? f), ...value.filter(f => !s.errorFiles.some(inner => inner.equivalentTo(f)))]
			: s.errorFiles.filter(inner => !value.some(v => v.equivalentTo(inner)))
	}));

	const addErrorFiles = async (files: File[]) => {
		setErrorFiles(files.map(f => new FileInfo(f, true)));

		files.forEach(f => dispatch(addDoc({id: state.id, docType: PaymentFileDocType.Errors, file: f}))
			.unwrap()
			.then(() => setErrorFiles(files.map(f => new FileInfo(f, false))))
		);
	};

	const removeErrorFile = async (file: FileInfo) => {
		setErrorFiles([new FileInfo(file, true)]);

		dispatch(removeDoc({id: state.id, docType: PaymentFileDocType.Errors, fileName: file.name}))
			.unwrap()
			.then(() => setErrorFiles([file], false));
	};

	const setRegisterFile = async (file: File | undefined) => {
		set({registerFile: file ? new FileInfo(file, true) : undefined});

		const doc = {id: state.id, docType: PaymentFileDocType.Register};
		await (file ? dispatch(addDoc({...doc, file})) : dispatch(removeDoc({...doc, fileName: state.registerFile!.name})))
			.unwrap()
			.finally(() => set({registerFile: file ? new FileInfo(file, false) : undefined}));
	};

	const cancel = () => navigate("..");

	const save = useCallback(() => {
		const validated = state.validate();
		setState(validated);
		if (hasError(validated)) return;

		setProcessing(true);
		dispatch(updatePaymentFile(state)).unwrap()
			.then(() => navigate(".."))
			.catch(() => alert("There is an error processing your request."))
			.finally(() => setProcessing(false));
	}, [dispatch, navigate, state, setProcessing]);

	const onView = useCallback(async (fileName: string, docType: PaymentFileDocType ) =>
		await dispatch(viewOrDownloadFile({id: state.id, docType, fileName}))
			.unwrap()
			.then(r => { openFileBlob(r.fileBlob); }), [dispatch, state.id]);

	const onDownload = useCallback(async (fileName: string, docType: PaymentFileDocType) =>
		await dispatch(viewOrDownloadFile({id: state.id, docType, fileName}))
			.unwrap()
			.then(r => { downloadFileBlob(r.fileBlob, r.fileName); }), [dispatch, state.id]);

	if (!file)
		return <Navigate to={".."}/>;

	return (
		<div className={styles.content}>
			<Typography variant={"h2"}>{file.name}</Typography>
			<Alert variant={"filled"} severity={"warning"}>
              Updating the status of this payment file to the "Closed" status requires a Pay Register File to be provided.
              Corresponding payments that reside in the file will be updated to "Payment Sent" and all other payment records will be marked as "Payment Failed.
			</Alert>

			<div className={styles.rateRow}>
				<Edit value={state.bankUploadDate} label={"Date Uploaded to Bank"} disabled={processing} onChange={v => set({bankUploadDate: v})}/>
				<FormControl fullWidth variant={"standard"} disabled={processing}>
					<InputLabel id={"status-label"}>Status</InputLabel>
					<Select labelId={"status-label"} value={state.status} onChange={e => set({status: e.target.value as FileStatus})} disabled={isReadOnlyStatus}>
						{Object.entries(FileStatus).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
					</Select>
				</FormControl>
			</div>

			<div className={styles.extendedRow}>
				<FormControl fullWidth error={!!state.errorState.notes}>
					<TextArea label={"Notes"} value={state.notes} error={!!state.errorState.notes} disabled={processing} onChange={v => set({notes: v})} rows={5}/>
					<FormHelperText error={!!state.errorState.notes}>{state.errorState.notes}</FormHelperText>
				</FormControl>
			</div>

			{[FileStatus.Closed].includes(state.status) && <>
				<div className={styles.extendedRow}>
					<FormControl fullWidth error={!!state.errorState.registerFile}>
						<DocumentsCard label={"Payment Register File"} files={state.registerFile ? [state.registerFile] : []}
							readonly={processing || isReadOnlyStatus} expanded
							accept={{ "text/plain": [] }}
							emptyLabel={"Please upload the pay register file to enable Ppulus to update the status of successful disbursements (status = Payment Sent). Line items not included in the Pay Register file will have their status set back to \"Upcoming\"."}
							onView={fileName => onView(fileName, PaymentFileDocType.Register)}
							onDownload={fileName => onDownload(fileName, PaymentFileDocType.Register)}
							onAdd={files => setRegisterFile(files.shift()!)}
							onDelete={_ => setRegisterFile(undefined)} />
						<FormHelperText error={!!state.errorState.registerFile}>{state.errorState.registerFile}</FormHelperText>
					</FormControl>
				</div>
				<div className={styles.extendedRow}>
					<DocumentsCard label={"Bank Error File(s)"} files={state.errorFiles}
						readonly={processing} expanded
						emptyLabel={"Please upload any error files to store within Ppulus as a reference source. Currently there is no automation / parsing of the files by Ppulus - the error line items need to be addressed manually."}
						onView={fileName => onView(fileName, PaymentFileDocType.Errors)}
						onDownload={fileName => onDownload(fileName, PaymentFileDocType.Errors)}
						onAdd={addErrorFiles}
						onDelete={removeErrorFile} />
				</div></>}

			<div className={styles.buttonGroup}>
				<Button variant={"outlined"} className={styles.button} disabled={processing} onClick={cancel}><IconCircleX className={styles.iconButton}/>Cancel</Button>
				<Button variant={"contained"} className={styles.button} disabled={processing} color={"primary"} onClick={save}><IconCheck className={styles.iconButton}/>Save</Button>
			</div>
		</div>
	);
};

export {
	DisbursementsPayFile
};