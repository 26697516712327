import { DropdownOption, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFullScreenButton } from "material-react-table";
import { ReactNode } from "react";
import { DeepKeyOf } from "./index";

export interface PpulusGridFilterItem {
	id?: number | string;
	columnField: string;
	value?: any;
	operatorValue?: string;
}

export type PpulusFilterValue = ColumnFiltersState;
export type PpulusColumn<T> = {
	field: DeepKeyOf<T> | null;
	header: string | null;
	headerStyle?: string;
	minSize?: number;
	maxSize?: number;
	size?: number;
	enableSorting?: boolean;
	filterVariant?: "autocomplete" | "checkbox" | "date" | "date-range" | "datetime" | "datetime-range" | "multi-select" | "range" | "range-slider" | "select" | "text" | "time" | "time-range";
	filterSelectOptions?: DropdownOption[];
	renderCell: (item: T) => ReactNode;
	initialSortDirection?: GridSortDirection;
};

//Following are required to fufill one of our linting rules about the casing in JSX components.

export const PpulusShowHideColumnsButton = MRT_ShowHideColumnsButton;
export const PpulusToggleFullScreenButton = MRT_ToggleFullScreenButton;
export const PpulusToggleDensePaddingButton = MRT_ToggleDensePaddingButton;

export const InitialSort = (columns: PpulusColumn<any>[]) => columns
	.filter(c => c.initialSortDirection)
	.filter(c => c.field !== null)
	.map(c => ({field: c.field, sort: c.initialSortDirection})) as PpulusGridSortItem[];

export interface PpulusGridFilterModel {
	items: PpulusGridFilterItem[];
	join?: string;
	linkOperator?: GridLinkOperator;
	quickFilterValues?: any[];
	quickFilterLogicOperator?: GridLinkOperator;
}

export type PpulusGridSortItem = {
	field: string;
	sort: GridSortDirection;
}

export interface PpulusGridInitialState<T> {
	pagination?: GridPaginationInitialState;
	sorting?: PpulusGridSortItem[];
	filter?: PpulusFilterValue;
	columns?: PpulusColumn<T>[];
}

export type PpulusFilterItem = {
  columnField: string;
  value?: any;
  operatorValue?: string;
}

export type ColumnFiltersState = PpulusColumnFilter[]
export type PpulusColumnFilter = {
	id: string;
	value:any;
	operatorValue?: string;
	type?: string;
}

export enum GridLinkOperator {
	And = "and",
	Or = "or"
}

export declare type GridSortDirection = "asc" | "desc" | null | undefined;

export interface GridPaginationInitialState {
	pageSize?: number;
	page?: number;
}

export interface PpulusPagination {
	pageSize: number;
	page: number;
}

export type GridDataModel = "client" | "server";
