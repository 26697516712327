import { Client, ClientStatus, DateDisplay} from "library";
import { PpulusColumn } from "types/grid";

class ClientRow {
	constructor(readonly id: string,
              readonly code: string,
              readonly firstName: string,
              readonly lastName: string,
              readonly dob: Date | undefined,
              readonly email: string | undefined,
              readonly phone: string | undefined,
              readonly programs: string[],
              readonly status: ClientStatus,
              readonly createdOn: Date,
              readonly modifiedOn: Date) {
	}

	public static Columns: PpulusColumn<ClientRow>[] = [
		{field: "code", header: "Code", minSize: 115, renderCell: row => row.code},
		{field: "firstName", header: "First Name", minSize: 160, renderCell: row => row.firstName},
		{field: "lastName", header: "Last Name", minSize: 160, renderCell: row => row.lastName},
		{field: "email", header: "Email", minSize: 200, renderCell: row => row.email},
		{field: "phone", header: "Phone",minSize: 100, renderCell: row => row.phone},
		{field: "dob", header: "Date of Birth", minSize: 120, renderCell: row => DateDisplay.Standard(row.dob)},
		{field: "programs", header: "Programs", minSize: 100, renderCell: row => row.programs},
		{field: "status", header: "Status", minSize: 100, renderCell: row => row.status},
		{field: "createdOn", header: "Created On", minSize: 120, renderCell: row => DateDisplay.Standard(row.createdOn)},
		{field: "modifiedOn", header: "Modified On", minSize: 120, renderCell: row => DateDisplay.Standard(row.modifiedOn)}
	];

	public static From(client: Client): ClientRow {
		return {
			id: client.id,
			code: client.code,
			firstName: client.primaryContact.firstName,
			lastName: client.primaryContact.lastName,
			dob: client.primaryContact.dateOfBirth,
			email: client.primaryContact.email?.address,
			phone: client.primaryContact.primaryPhone?.number,
			programs: client.activeProgramNames,
			status: client.status,
			createdOn: client.createdOn,
			modifiedOn: client.modifiedOn
		};
	};
}

export {ClientRow};