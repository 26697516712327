import styles from "styles/client.module.scss";

import React, {ReactNode, useCallback, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {ApplicantInformation, Application, Client, Household, Person} from "library";
import {ADULT, DEPENDANT, HouseholdMemberActions, IncomeDetails, MemberDetail} from "components";
import {setClient} from "store/slices";
import {useDispatch, useSelector} from "store";

const ClientHouseholds: (props: { primary: ApplicantInformation | undefined, household: Household | undefined, readonly: boolean }) => ReactNode = ({primary, household, readonly}) => {
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    const [newMember, setNewMember] = useState<Person>();
    const [state, setState] = useState({primary: new ApplicantInformation(primary), household: new Household(household)});
    const {item: client} = useSelector(s => s.client);

    const onAddNew = useCallback(async (member: Person | ApplicantInformation) => {
        if (!client || !household) return;
        setProcessing(true);

        setNewMember(member);
        const newAdults = member.isDependant ? [...household.dependants, member] : household.dependants;
        const newDependants = !member.isDependant ? [...household.adults, member] : household.adults;
        dispatch(setClient({clientValue: new Client({
            ...client,
            household: {
                adults: newAdults,
                dependants: newDependants
            }
        })})).unwrap().then(c => {
            setNewMember(undefined);
            setState({ primary: c.primaryContact, household: c.household });
            setProcessing(false);
        });
    }, [dispatch, client, household]);

    const save = useCallback(async (newValue: { adult?: Person | ApplicantInformation, dependant?: Person }, index: number) => {
        if (!client || !household) return;

        setProcessing(true);
        const newAdults = household.adults.map((a, i) => i === index ? newValue?.adult ?? a : a);
        const newDependants = household.dependants.map((d, i) => i === index ? newValue?.dependant ?? d : d);

        await dispatch(setClient({
            clientValue: new Client({
            ...client,
            household: {
                adults: newAdults,
                dependants: newDependants
            }
        })})).unwrap().then(c => {
            setState({ primary: c.primaryContact, household: c.household });
            setProcessing(false);
        });
    }, [dispatch, client, household]);

    const onDelete = useCallback(async (member: Person | ApplicantInformation, index: number) => {
        if (!client || !household) return;
        setProcessing(true);
        const adults = !member.isDependant ? household.adults.filter((a, i) => i !== index) : household.adults;
        const dependants = member.isDependant ? household.dependants.filter((d, i) => i !== index) : household.dependants;

        await dispatch(setClient({clientValue: new Client({
            ...client,
            household: { adults, dependants }
        })})).unwrap().then(c => {
            setState({ primary: c.primaryContact, household: c.household });
            setProcessing(false);
        });
    }, [dispatch, client, household]);

    const saveIncome = useCallback(async (value: Partial<Application>) => {
        if (!client) return;
        setProcessing(true);
        await dispatch(setClient({clientValue: new Client({
            ...client,
            primaryContact: value.applicant ?? client.primaryContact,
            household: value.household ?? client.household
        })})).unwrap().then(c => {
            setState({primary: c.primaryContact, household: c.household});
            setProcessing(false);
        });
    }, [dispatch, client]);
        
    return (
        <div className={styles.contentBg}>
            <Grid container>
                <div className={styles.tabTitle}>
                    <Typography variant={"h3"} className={styles.title}>
                        <span className={styles.fullWidth}>Household Members ({state.household?.count || "None"})</span>
                        {!readonly && <HouseholdMemberActions processing={processing} onAddAdult={() => setNewMember(new Person({isDependant: false}))} onAddChild={() => setNewMember(new Person({isDependant: true}))}/>}
                    </Typography>
                </div>
            </Grid>
            {newMember &&
                <div className={`${styles.card} ${styles.fullWidth} ${styles.memberDetailContainer}`}>
                    <MemberDetail label={!newMember.isDependant ? ADULT : DEPENDANT} value={newMember} editing onCancel={() => setNewMember(undefined)} onChange={onAddNew} />
                </div>
            }
            {state.household?.adults.map((a, i) =>
                <MemberDetail key={i} value={a} label={"Adult"} readonly={readonly} className={styles.formDetail} onChange={async v => save({adult: v}, i)} onDelete={() => onDelete(a, i)}/>)
            }
            {state.household?.dependants.map((a, i) =>
                <MemberDetail key={i} value={a} label={"Dependant"} readonly={readonly} className={styles.formDetail} onChange={async v => save({dependant: v}, i)} onDelete={() => onDelete(a, i)}/>)
            }

            <IncomeDetails applicant={state.primary} readonly={readonly} household={state.household} onChange={saveIncome}/>
        </div>
    );
};

export {
    ClientHouseholds
};