import styles from "styles/application.module.scss";

import React, {ReactNode, useEffect, useState} from "react";
import {Address, ApplicantInformation, CitizenStatus, fullWidth, Gender, halfWidth, hasError, oneThirdWidth, Person, Phone, quarterWidth, RelationToApplicant, StudentStatus, twoThirdWidth, YesNo} from "library";
import {Button, Dialog, DialogActions, DialogTitle, FormControl, FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import {Display, Edit, FormValueDisplay} from "components/FormValueDisplay";
import {SinDisplay} from "library/formatters";
import {AddressDisplay} from "components/AddressDisplay";
import {AddressForm} from "components/AddressForm";
import {Actions} from "./Actions";
import {YesNoButton} from "../YesNoButton";
import {PpulusLoader} from "./PpulusLoader";

interface MemberDetailProps {
	value: Person | ApplicantInformation;
	readonly?: boolean;
	label: string;
	editing?: boolean;
	className?: string;
	requireSin?: boolean;
	onChange?: (value: Person | ApplicantInformation) => Promise<void>;
	onCancel?: () => void;
	onDelete?: () => Promise<void>;
}

const MemberDetail: (props: MemberDetailProps) => ReactNode = ({value, readonly, label, editing, className, requireSin, onChange, onDelete, onCancel}) => {
	const [editMode, setEditMode] = useState(editing ?? false);
	const [processing, setProcessing] = useState(false);
	const [editValue, setEditValue] = useState(value);
    const [dialog, setDialog] = useState<boolean>();
	
	useEffect(() => {
		setEditValue(value);
	}, [value]);

	const set = (v: Partial<Person | ApplicantInformation>) => {
		setEditValue(editValue instanceof ApplicantInformation ? new ApplicantInformation({...editValue, ...v}) : new Person({...editValue, ...v}));	
	}

	const onInnerCancel = () => {
		setEditValue(value);
		setEditMode(false);
		onCancel?.();
	};
	
	const onSave = async () => {
		const validated = editValue.validate();
		setEditValue(validated);
		if (hasError(validated) || !onChange) return;
		
		setProcessing(true);
		onChange(validated)
			.then(() => setEditMode(false))
			.finally(() => setProcessing(false));
	};

	const deleteMember = () => {
        if (!onDelete) return;

        setDialog(true);
    };

    const onClose = () => setDialog(false);

	const onAccept = async () => {
		if (!onDelete) return;
		setProcessing(true);
		onDelete()
			.finally(() => {
				setProcessing(false);
				onClose();
			});
	};
			
	return (
		<Grid container className={styles.memberDetailContainer}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"} className={styles.memberLabel}>
					{label} (Age {value.age}) {!readonly && <Actions processing={processing} editMode={editMode} onEdit={() => setEditMode(true)} onSave={onSave} onDelete={value.isComplete ? deleteMember : undefined} onCancel={onInnerCancel}/>}
				</Typography>
			</Grid>

			<Grid item {...quarterWidth} className={className}>
				<FormValueDisplay label={"First Name"} value={!editMode ? <Display value={value.firstName} /> : <Edit value={editValue.firstName} disabled={processing} error={editValue.errorState.firstName} onChange={v => set({firstName: v})} />} />
				<FormValueDisplay label={"Middle Name"} value={!editMode ? value.middleName : <Edit value={editValue.middleName} disabled={processing} error={editValue.errorState.middleName} onChange={v => set({middleName: v})} />} />
				<FormValueDisplay label={"Last Name"} value={!editMode ? value.lastName : <Edit value={editValue.lastName} disabled={processing} error={editValue.errorState.lastName} onChange={v => set({lastName: v})} />} />
				<FormValueDisplay label={"Preferred Name"} value={!editMode ? value.preferredName : <Edit value={editValue.preferredName} disabled={processing} error={editValue.errorState.preferredName} onChange={v => set({preferredName: v})} />}  />
			</Grid>
			<Grid item {...quarterWidth} className={`${className} ${styles.memberDetailThreeRowsMargin}`}>
				{!(value instanceof ApplicantInformation) && <FormValueDisplay label={"Relationship"} value={!editMode
					? value.relationToApplicant
					: <FormControl error={!!editValue.errorState.relationToApplicant} fullWidth>
						<Select labelId={"relationship-error"} variant={"standard"} value={editValue.relationToApplicant} disabled={processing} error={!!editValue.errorState.relationToApplicant}
								onChange={e => set({relationToApplicant: e.target.value as RelationToApplicant})} fullWidth>
							{Object.entries(RelationToApplicant).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
						</Select>
						<FormHelperText>{editValue.errorState.relationToApplicant}</FormHelperText>
					</FormControl>}
				/>}
				<FormValueDisplay label={"Status in Canada"} value={!editMode ? <Display value={value.citizenship !== CitizenStatus.NotSpecified ? value.citizenship : ""}/>
					: <FormControl error={!!editValue.errorState.citizenship} fullWidth>
						<Select labelId={"error-label"} variant={"standard"} value={editValue.citizenship} disabled={processing} error={!!editValue.errorState.citizenship} onChange={e => set({citizenship: e.target.value as CitizenStatus})}
										fullWidth>
							{Object.entries(CitizenStatus)
								.filter(([k]) => k !== CitizenStatus.NotSpecified)
								.map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
						</Select>
						<FormHelperText>{editValue.errorState.citizenship}</FormHelperText>
					</FormControl>
				}/>
				<FormValueDisplay label={"DOB"}
								  value={!editMode
									  ? <Display value={value.dateOfBirth} longDate/>
									  : <Edit value={editValue.dateOfBirth ?? new Date()} disabled={processing} error={editValue.errorState.dateOfBirth} onChange={v => set({dateOfBirth: v})}/>}
				/>
				<FormValueDisplay label={"Gender"} value={!editMode
					? value.gender 
					: <FormControl error={!!editValue.errorState.gender} fullWidth>
						<Select labelId={"error-label"} variant={"standard"} value={editValue.gender} disabled={processing} error={!!editValue.errorState.gender} onChange={e => set({gender: e.target.value as Gender})} fullWidth>
							{Object.entries(Gender).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
						</Select>
						<FormHelperText>{editValue.errorState.gender}</FormHelperText>
					</FormControl>
				} />
				<FormValueDisplay label={"Student Status"} value={!editMode
					? value.studentStatus
					: <FormControl error={!!editValue.errorState.studentStatus} fullWidth>
						<Select labelId={"error-label"} variant={"standard"} value={editValue.studentStatus} disabled={processing} error={!!editValue.errorState.studentStatus} onChange={e => set({studentStatus: e.target.value as StudentStatus})}
								fullWidth>
							{Object.entries(StudentStatus).map(([k, v]) => <MenuItem key={k} value={v}>{v}</MenuItem>)}
						</Select>
						<FormHelperText>{editValue.errorState.studentStatus}</FormHelperText>
					</FormControl>
				}/>
				{requireSin && <FormValueDisplay label={"SIN #"} value={!editMode 
					? SinDisplay(value.socialInsuranceNumber) 
					: <Edit value={editValue.socialInsuranceNumber} disabled={processing} error={editValue.errorState.socialInsuranceNumber} onChange={v => set({socialInsuranceNumber: v})} />
				} />}
			</Grid>

			{value instanceof ApplicantInformation && <>
                <Grid item {...halfWidth} className={`${className} ${styles.memberDetailThreeRowsMargin}`}>
                    <FormValueDisplay label={"Email"} labelWidth={oneThirdWidth}
                                      value={!editMode 
	                                      ? value.email?.address 
	                                      : <Edit value={editValue.email?.address} disabled={processing} error={editValue.errorState.email} onChange={v => set({email: {...editValue.email, address: v}})} />}
                                     valueWidth={twoThirdWidth} 
                    />
                    <FormValueDisplay label={"Primary"} labelWidth={oneThirdWidth}
                                      value={!editMode 
	                                      ? value.primaryPhone?.number 
	                                     : <Edit value={new Phone(editValue.primaryPhone)} disabled={processing} error={editValue.errorState.primaryPhone} onChange={v => set({primaryPhone: v})} />}
                                      valueWidth={twoThirdWidth}
                    />
                    <FormValueDisplay label={"Secondary"} labelWidth={oneThirdWidth}
                                      value={!editMode 
	                                      ? value.secondaryPhone?.number 
	                                      : <Edit value={new Phone(editValue.secondaryPhone)} disabled={processing} error={editValue.errorState.secondaryPhone} onChange={v => set({secondaryPhone: v})} />}
                                      valueWidth={twoThirdWidth}
                    />
                    <FormValueDisplay className={styles.address} label={"Address"} labelWidth={oneThirdWidth} 
                                      value={!editMode
	                                      ? <AddressDisplay value={value.address} singleLine />
										  : <AddressForm lookup={"addressLookup"} value={editValue.address ?? new Address()} onChange={v => set({address: new Address(v)})}/>}
									  valueWidth={twoThirdWidth}
					/>

					<FormValueDisplay className={styles.address} label={"Mailing Address"} labelWidth={oneThirdWidth}
									  value={!editMode
										  ? <AddressDisplay value={value.mailingAddress} singleLine/>
										  : <>
											  <FormValueDisplay label={"Same as address?"} value={<YesNoButton value={editValue.mailAddressUnique ? YesNo.No : YesNo.Yes}
																											   onChange={yesNo => set({mailingAddress: new Address(yesNo === YesNo.Yes ? editValue.address : undefined)})}/>}
											  />
											  {editValue.mailAddressUnique &&
												  <AddressForm lookup={"mailLookup"} value={editValue.mailingAddress ?? new Address()} onChange={v => set({mailingAddress: new Address(v)})}/>
											  }
										  </>}
					/>
                </Grid>
            </>}
			<Dialog
                open={!!dialog}
                onClose={onClose}>
                <DialogTitle>{"Are you sure you want to remove this household member?"} {processing && <PpulusLoader />}</DialogTitle>
                <DialogActions>
                    <Button onClick={onClose}>No (Cancel)</Button>
                    <Button onClick={onAccept}>Yes</Button>
                </DialogActions>
            </Dialog>
		</Grid>
	);
}

export {
	MemberDetail
};