// auth provider
import {ContextHolder, useAuth as useFronteggAuth, useLoginWithRedirect,} from "@frontegg/react";
import {AuthenticationContextType} from "../types/auth";
import {useCallback} from "react";

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = (): AuthenticationContextType => {
    const {user, isAuthenticated} = useFronteggAuth();
    const loginWithRedirect = useLoginWithRedirect();

    const logout = useCallback(() => {
        const baseUrl = ContextHolder.getContext().baseUrl;
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.origin}`;
    }, []);
    
    return {
        user,
        canEffect: (...permissions: string[])=> user?.permissions.some(r => permissions.includes(r.key)) ?? false,
        isInitialized: true,
        isLoggedIn: isAuthenticated,
        login: loginWithRedirect,
        logout
    }
};

export default useAuth;