import styles from "styles/application.module.scss";

import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addDocuments, approveApplication, changeApplicationStatus, deleteApplicationDocument, getApplication, offerApplication, requestDocuments, setApplication } from "store/slices";
import { Application, ApplicationContent, ApplicationStatus, ApplicationType, ApproveApplicationState, Document, fullWidth, IDocuments, Note, OfferApplicationState, PermissionRequiredFor, PpulusRoutes, ReadonlyApplicationStatuses } from "library";
import { useDispatch, useSelector } from "store";
import { IconAlertTriangle, IconAppWindow, IconArrowBack, IconFileCertificate, IconFileDollar, IconFileStack, IconUsers } from "@tabler/icons";
import { Button, Grid, Link, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ApplicationForm } from "./ApplicationForm";
import { ApplicationHousehold } from "./Application.Household";
import { ApplicationNotes } from "./Application.Notes";
import { ApplicationDocumentsForm } from "./Application.Documents";
import { AssignmentsForm } from "../../components/Assignment.form";
import { AuditDetails, PpulusLoader } from "components";
import { ApplicationChanges } from "./Application.Changes";
import { ApplicationDuplicates } from "./Application.Duplicates";
import { ApplicationEmails } from "./Application.Emails";
import { IconEmailSent } from "components/icons/IconEmailSent";
import useAuth from "hooks/useAuth";
import MuiChip from "@mui/material/Chip";

type ApplicationTab = { tab: JSX.Element, content: JSX.Element };

const ApplicationCard = () => {
	const {id} = useParams();
	const {canEffect} = useAuth();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {loading, processing} = useSelector(s => s.application);
	const {programs} = useSelector(s => s.programs);
	const [value, setValue] = useState<Application>();
	const [currentTab, setCurrentTab] = useState("General");

	useEffect(() => {
		if (!id) return;

		dispatch(getApplication(id))
			.unwrap()
			.then(setValue);
	}, [dispatch, id]);

	const permissionRequired = useMemo(() => PermissionRequiredFor(value?.type, value?.status), [value]);
	
	const readonly = useMemo(() => !canEffect(permissionRequired) || !value || value.isOnOffer || ReadonlyApplicationStatuses.includes(value.status), [value, canEffect, permissionRequired]);

	const save = useCallback(async (newValues: Partial<Application>, note?: Note) => {
		if (!value || !newValues) return;

		await dispatch(setApplication(new Application({
			...value,
			...newValues,
			notes: [...(newValues.notes ?? value.notes), ...(note ? [note] : [])]
		})))
			.unwrap()
			.then(setValue);
	}, [dispatch, value, setValue]);

	const submitDocumentRequest = useCallback(async ({application, specialMessage, sendEmail}: { application: Application, specialMessage: string, sendEmail: boolean }) => {
		await dispatch(requestDocuments({application, specialMessage, sendEmail}))
			.unwrap()
			.then(setValue);
	}, [dispatch]);

	const changeStatus = useCallback(async ({status, message, notes, sendEmail}: { status: ApplicationStatus, message: string, notes: string, sendEmail: boolean }) => {
		await dispatch(changeApplicationStatus({status, message, notes, sendEmail}))
			.unwrap()
			.then(setValue);
	}, [dispatch, setValue]);

	const approve = useCallback(async (approval: ApproveApplicationState) => {
		if (!value) return;
		await dispatch(approveApplication(approval))
			.unwrap()
			.then(setValue);
	}, [value, dispatch]);

	const offer = useCallback(async (Offer: OfferApplicationState) => {
		if (!value) return;
		await dispatch(offerApplication(Offer))
			.unwrap()
			.then(setValue);
	}, [value, dispatch]);

	const deleteDocument = useCallback(async (document: Document) => {
		await dispatch(deleteApplicationDocument(document))
			.unwrap()
			.then(setValue);
	}, [dispatch]);

	const addSupportingDocuments = useCallback(async ({files, type}: { files: File[], type: keyof IDocuments }) => {
		await dispatch(addDocuments({files, type}))
			.unwrap()
			.then(setValue);
	}, [dispatch]);

	const ApplicationTabs: { [key: string]: ApplicationTab } = useMemo(() => ({
		General: {
			tab: <><IconAppWindow/> Main (Full View)</>, content: <ApplicationForm application={value}
																				   onSave={save}
																				   onRequestDocuments={submitDocumentRequest}
																				   onStatusChange={changeStatus}
																				   onApprove={approve}
																				   onOffer={offer}
																				   onAddSupportingDocument={addSupportingDocuments}
																				   onDeleteSupportingDocument={deleteDocument}/>
		},
		Household: {tab: <><IconUsers/> Household</>, content: <ApplicationHousehold value={value} readonly={readonly} onSave={save}/>},
		Notes: {tab: <><IconFileDollar/> Notes</>, content: <ApplicationNotes value={value?.notes ?? []} readonly={!value || ReadonlyApplicationStatuses.includes(value.status)} onChange={notes => save({notes})}/>},
		Documents: {tab: <><IconFileCertificate/> Documents</>, content: <ApplicationDocumentsForm value={value} readonly={readonly} onDelete={deleteDocument} onAdd={addSupportingDocuments}/>},
		...(value?.type === ApplicationType.Standard && {
			PotentialDuplicates: {tab: <><IconAlertTriangle/> Potential Duplicates</>, content: <ApplicationDuplicates application={value} />},
			Emails: {tab: <><IconEmailSent/> Emails</>, content: <ApplicationEmails applicationCode={value?.code}/>}
		}),
		...(value?.changes?.length && {Changes: {tab: <><IconFileStack/> Changes</>, content: <ApplicationChanges changes={value.changes}/>}})
	}), [value, save, submitDocumentRequest, changeStatus, approve, offer, addSupportingDocuments, deleteDocument, readonly]);

	const offerStatus = useMemo(() => !!value && (value.isOnOffer ? " - On Offer" : value.isOfferAccepted ? " - Accepted" : ""), [value]);

	if (loading && !processing)
		return <PpulusLoader/>;

	if (!value)
		return <div>Application could not be found</div>;

	return (
		<Grid width={"100%"} className={styles.content}>
			<Grid container>
				<Grid item>
					<Typography variant={"h2"} className={styles.header}>{ApplicationContent[value.type].heading} #{value.code}</Typography>
				</Grid>
				<Grid item flexGrow={"1"}>
					<AssignmentsForm value={value.assignments} onSave={assignments => save({assignments})}/>
				</Grid>
				<Stack justifyContent={"center"} className={styles.header}>
					<Button variant={"outlined"} color={"primary"} startIcon={<IconArrowBack/>} size="small" onClick={() => navigate("../all", {relative: "path"})}>Return to listing</Button>
				</Stack>
				<Grid item {...fullWidth} className={`${styles.header} ${styles.divider}`}>
					<Stack className={styles.statusHeader}>
						<div>Status</div>
						<div className={styles.applicationStatus}>{`${value.status}${offerStatus}`}</div>
						{value.type === ApplicationType.Standard &&
							<>
								<div>Confirmation #</div>
								<div className={styles.applicationStatus}>{value.confirmation}
								</div>
							</>
						}
						{!!value.clientCode &&
							<>
								<div>Recipient</div>
								<div className={styles.applicationStatus}><Link sx={{cursor: "pointer"}} onClick={() => navigate(`${PpulusRoutes.Clients}/${value.clientCode}`, {relative: "path"})}>{value?.clientCode}</Link>
								</div>
							</>
						}
						{!!value.programs &&
							<>
								<div className={styles.center}>Program(s)</div>
								<div className={styles.chips}>
									{value.programs.map(p =>
										<MuiChip key={p} label={programs.find(inner => inner.shortName === p)?.longName} color={"warning"}/>)
									}
								</div>
							</>
						}
					</Stack>
				</Grid>
			</Grid>

			<Tabs className={styles.tabs} value={currentTab} variant={"scrollable"} onChange={(_, tab) => setCurrentTab(tab)}>
				{Object.entries(ApplicationTabs)
					.map(([k, v]) => <Tab iconPosition="start" key={k} label={v.tab} value={k}/>)}
			</Tabs>
			{ApplicationTabs[currentTab].content}
			<AuditDetails value={value}/>
		</Grid>
	);
};

export {
	ApplicationCard
};