import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project imports
import { BASE_PATH } from 'config';
import { gridSpacing } from 'store/constant';

// assets
import { IconTallymark1 } from '@tabler/icons';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { NavItemType, NavItemTypeObject, OverrideIcon } from 'types';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

const useIconStyle = () => {
    const theme = useTheme();

    return {
        iconStyle: {
            marginRight: theme.spacing(0.75),
            marginTop: `-${theme.spacing(0.25)}`,
            width: '1rem',
            height: '1rem',
            color: theme.palette.secondary.main
        }
    };
};

interface BreadCrumbSxProps extends React.CSSProperties {
    mb?: string;
    bgcolor?: string;
}

const MainContent = ({ main, icons }: { main: NavItemType | undefined, icons?: boolean }) => {
    const { iconStyle } = useIconStyle();

    if (main?.type !== "collapse")
        return undefined;

    const CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;

    return (
        <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
            {icons && <CollapseIcon style={iconStyle} />}
            {main.title}
        </Typography>
    );
};

const ItemContent = ({ item, icons, title }: { item: NavItemType | undefined, title?: ReactNode, icons?: boolean }) => {
    const { iconStyle } = useIconStyle();

    if (item?.type !== 'item')
        return undefined;

    const ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;

    return (
        <Typography variant="subtitle1" sx={{
            display: 'flex',
            textDecoration: 'none',
            alignContent: 'center',
            alignItems: 'center',
            color: 'grey.500'
        }}
        >
            {icons && <ItemIcon style={iconStyle} />}
            {title}
        </Typography>
    );
}

interface BreadCrumbsProps {
    card?: boolean;
    divider?: boolean;
    icon?: boolean;
    icons?: boolean;
    maxItems?: number;
    navigation?: NavItemTypeObject;
    rightAlign?: boolean;
    separator?: OverrideIcon;
    title?: boolean;
    titleBottom?: boolean;
    sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
    card,
    divider,
    icon,
    icons,
    maxItems,
    navigation,
    rightAlign,
    separator,
    title,
    titleBottom,
    ...others
}: BreadCrumbsProps) => {
    const theme = useTheme();
    const location = useLocation();
    const { iconStyle } = useIconStyle();
    const [main, setMain] = useState<NavItemType | undefined>();
    const [item, setItem] = useState<NavItemType>();

    const getCollapse = useCallback((menu: NavItemTypeObject) => {
        if (!menu.children) return;

        menu.children.forEach((collapse: NavItemType) => {
            if (collapse.type === 'collapse')
                getCollapse(collapse as { children: NavItemType[]; type?: string });

            if (collapse.type === 'none' && location.pathname.startsWith(`${BASE_PATH}${collapse.url}`)) {
                setMain(menu);
                setItem(collapse);
                return;
            }

            const menuItem = collapse?.children?.find(m => m.type === 'item' && location.pathname === (`${BASE_PATH}${m?.url}`))
                ?? collapse?.children?.find(m => m.type === 'item' && location.pathname.startsWith(`${BASE_PATH}${m?.url}`));

            if (!menuItem) return;

            setMain(menu);
            setItem(menuItem)
        })
    }, [location]);

    useEffect(() => {
        navigation?.items?.map((menu: NavItemType | NavItemTypeObject) => {
            if (menu.type && menu.type === 'group')
                getCollapse(menu as { children: NavItemType[]; type?: string });

            return false;
        });
    }, [getCollapse, navigation?.items]);

    // item separator
    const SeparatorIcon = separator!;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="1rem" /> : <IconTallymark1 stroke={1.5} size="1rem" />;

    return (
        <Card sx={{ marginBottom: card === false ? 0 : theme.spacing(gridSpacing), background: card === false ? 'transparent' : theme.palette.background.default }} {...others}>
            <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                <Grid container direction={rightAlign ? 'row' : 'column'} justifyContent={rightAlign ? 'space-between' : 'flex-start'} alignItems={rightAlign ? 'center' : 'flex-start'} spacing={1}>
                    {title && !titleBottom && (
                        <Grid item>
                            <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                {item?.title}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <MuiBreadcrumbs sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }} aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
                            <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX}>
                                {icons && <HomeTwoToneIcon sx={iconStyle} />}
                                {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
                                {!icon && 'Dashboard'}
                            </Typography>
                            {main?.type === "collapse" && <MainContent main={main} icons={icons} />}
                            <ItemContent item={item} icons={icons} title={item?.title} />
                        </MuiBreadcrumbs>
                    </Grid>
                    {title && titleBottom && (
                        <Grid item>
                            <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                {item?.title}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
            {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }} />}
        </Card>
    );
};

export default Breadcrumbs;
