import styles from "styles/fileDropzone.module.scss";

import React, {useCallback, useMemo, useState} from "react";
import {Accept, useDropzone} from "react-dropzone";
import {PpulusLoader} from "./household";

type FileDropzoneProps = {
    readonly?: boolean;
    accept?: Accept;
    onFileAdd: (file: File[]) => Promise<void>
}

const FileDropzone = ({onFileAdd, readonly = false, accept}: FileDropzoneProps) => {
    const [processing, setProcessing] = useState(false);

    const onAdd = useCallback((file: File[]) => {
        setProcessing(true);
        onFileAdd(file).finally(() => setProcessing(false));
    }, [onFileAdd]);

    const {getRootProps, getInputProps, isDragAccept} = useDropzone({
        disabled: readonly,
        accept: accept,
        onDrop: files => onAdd(files.map(f => new File([f], f.name.replace(/\+/g, "_"), {type: f.type}))),
        onDragEnter: (e: any) => e.target.style.cursor = 'dragging'
    });

    const classes = useMemo(() => ([
        styles.dropzoneContainer,
        ...(isDragAccept ? [styles.accepted] : [])
    ]), [isDragAccept]);

    return (processing
            ? <PpulusLoader/>
            : (
                <div {...getRootProps()} className={classes.join(" ")}>
                    <input {...getInputProps()} />
                    Drop files in here or click to select from dialog
                </div>
            )
    );
};

export {
    FileDropzone,
    type Accept
}