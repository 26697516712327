import {createSlice} from "@reduxjs/toolkit";
import {Address} from "library";

const canadaPost = createSlice({
	name: "canadaPost",
	initialState: {initialized: false, scriptLoaded: false, cssLoaded: false, lookup: "", address: new Address()},
	reducers: {
		setInitialized: (state) => ({...state, initialized: true}),
		setScriptLoaded: (state) => ({...state, scriptLoaded: true}),
		setCssLoaded: (state) => ({...state, cssLoaded: true}),
		setAddress: (state, {payload}) => ({...state, lookup: payload.lookup, address: payload.address})
	}
});

export default canadaPost.reducer;

export const {setInitialized, setScriptLoaded, setCssLoaded, setAddress} = canadaPost.actions;