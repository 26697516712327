import axios, {AxiosError, AxiosResponse} from "axios";
import {
  Announcement,
  AnnouncementType,
  Application,
  Client,
  DeactivationReason,
  EmailTemplate,
  GeneralSettings,
  IAnnouncement,
  IApiScreeningResult,
  IApplications,
  IClients,
  Identity,
  IJourneyEntry,
  IncomeType,
  IPageList,
  IPayment,
  IPaymentSummary,
  IProgress,
  Journey,
  Payment,
  PaymentFile,
  PaymentFileDocType,
  Period,
  ProgramApproval,
  ProgramBudget,
  RateRule,
  Rates as RatesCollection,
  removeLineBreaks,
  ScreeningResult,
  SentEmail
} from "library";
import {toast} from "react-toastify";
import {PaginatedResponse, ServiceType} from "models";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {User} from "@frontegg/redux-store";

const sleep = () => new Promise(resolve => setTimeout(resolve, 100));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;

export type QueryResult<T> = { items: T[], metaData: { totalCount: number } };
const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const getUrlParams = (value: object) => new URLSearchParams(Object.fromEntries(Object.entries(value).filter(([, v]) => v === 0 || !!v)));

axios.interceptors.response.use(async response => {
  if (process.env.NODE_ENV === 'development') await sleep();
  const pagination = response.headers['pagination'];
  if (pagination) {
    response.data = new PaginatedResponse(response.data, JSON.parse(pagination));
    return response;
  }
  return response;
}, (error: AxiosError) => {
  const {data, status} = error.response as AxiosResponse;
  switch (status) {
    case 400:
      if (data.errors) {
        const modelStateErrors: string[] = [];
        for (const key in data.errors) {
          if (data.errors[key]) {
            modelStateErrors.push(data.errors[key])
          }
        }
        throw modelStateErrors.flat();
      }
      toast.error(data.title);
      break;
    case 401:
      toast.error(data.title);
      break;
    case 403:
      toast.error('You are not allowed to do that!');
      break;
    default:
      break;
  }
  return Promise.reject(error.response);
})

const requests = {
  get: <T>(url: string, params?: URLSearchParams, opts?: { headers?: any, signal?: AbortSignal }) => axios.get<T>(url, {params, headers: opts?.headers, signal: opts?.signal}).then(responseBody),
  post: <T>(url: string, body: {}, headers: any = undefined) => axios.post<T>(url, body, {headers}).then(responseBody),
  put: <T>(url: string, body: {}, headers: any = undefined) => axios.put<T>(url, body, {headers}).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  getFile: (url: string) => axios.get(url, {responseType: "blob"}).then(response => 
    ({fileBlob: new Blob([response.data], {type: response.headers["content-type"]}), fileName: response.headers["content-disposition"]?.match(/filename="(?<fileName>.*?)"/)?.groups?.["fileName"] ?? ""})),
  putFile: (url: string, file: File) => axios.post(url, file, {headers: {"Content-type": file.type, "X-FileName": file.name}}).then(responseBody),
  postForm: (url: string, data: FormData) => axios.post(url, data, {
    headers: {'Content-type': 'multipart/form-data'}
  }).then(responseBody),
  putForm: (url: string, data: FormData) => axios.put(url, data, {
    headers: {'Content-type': 'multipart/form-data'}
  }).then(responseBody)
}

function createFormData(item: any) {
  let formData = new FormData();
  for (const key in item) {
    formData.append(key, item[key])
  }
  return formData;
}

const setUser = (user: User | null | undefined) => {
  axios.defaults.headers.common = {"Authorization": user ? `bearer ${user.accessToken}` : undefined};
};

const ServiceTypes = {
  list: (params: URLSearchParams) => requests.get<IPageList<ServiceType>>('ServiceType', params),
  details: (serviceTypeId: string) => requests.get<ServiceType>(`ServiceType/${serviceTypeId}`),
  createServiceType: (serviceType: any) => requests.postForm('ServiceType', createFormData(serviceType)),
  updateServiceType: (serviceType: any) => requests.putForm('ServiceType', createFormData(serviceType)),
  deleteServiceType: (serviceTypeId: string) => requests.delete(`ServiceType/${serviceTypeId}`)
}

const Clients = {
  search: async (searchValue: string) => {
    const url = `Client/search/${searchValue}`;
    return requests.get<QueryResult<Client>>(url);
  },
  list: async (options: FilterOptions | undefined, forMyClients: boolean = false) => {
    const url = forMyClients ? 'client/my-clients' : 'client';
    const searchParams = !!options ? getUrlParams({ pageNumber: `${options.page}`, pageSize: options.pageSize, sort: options.sort?.field, direction: options.sort?.sort }) : undefined;
    const filter = !!options ? { filter: JSON.stringify(options.filter) } : undefined;
    return requests
        .get<QueryResult<Client>>(url, searchParams, {headers: filter})
      .then(result => ({ items: result.items.map(c => new Client(c)), count: result.metaData.totalCount }))
  },
  get: (id: string) => requests
      .get<Client>(`client/${id}`)
      .then(result => new Client(result)),
  getDisbursements: (id: string) => requests
      .get<QueryResult<Payment>>(`client/${id}/payments`, getUrlParams({pageSize: 100, sort: "period", direction: "desc"}))
      .then(result => result.items.map(r => new Payment(r))),
  getJourney: (id: string) => requests
      .get<IJourneyEntry[]>(`client/${id}/journey`)
      .then(r => new Journey(...r)),
  getEmail: (id: string, email: EmailTemplate, program?: ProgramApproval, signal?: AbortSignal) => requests
      .get<string>(`client/${id}/emails/${email}`, undefined, {signal, headers: {"X-Program": JSON.stringify(program)}})
      .then(),
  getEmails: (code: string) => requests
      .get<SentEmail[]>(`client/${code}/emails`)
      .then(r => r
              ?.map(e => new SentEmail(e.id, e.subject, e.message, e.createdOn))
              .sort((x, y) => x.createdOn.getDate() - y.createdOn.getDate())
          ?? []),
  update: (client: Client, reason: string = "", sendEmail = true) => requests
      .post<Client>("client", client, {"X-Status-Reason": removeLineBreaks(reason), "X-Send-Email": sendEmail})
      .then(c => new Client({...client, ...c})),
  addFile: (id: string, file: File, documentType: string) => requests
      .putFile(`client/${id}/documents/${documentType}/${file.name}`, file),
  getFile: (id: string, fileName: string, documentType: string) => requests
      .getFile(`client/${id}/documents/${documentType}/${fileName}`),
  getFinancialInfo: (id: string) => requests
      .get(`client/${id}/financialInfo`)
      .then(),
}

type FilterOptions = { page?: number, pageSize?: number, sort?: GridSortItem, filter?: GridFilterModel };

const Applications = {
  search: async (searchValue: string) => {
    const url = `Application/search/${searchValue}?applicationTypes=Standard`;
    return requests.get<QueryResult<Application>>(url);
  }, 
  list: async (options: FilterOptions | undefined, forMyApplications: boolean = false, abort?: AbortSignal) => {
    const url = forMyApplications ? 'application/my-applications' : 'application';
    const searchParams = !!options ? getUrlParams({ pageNumber: `${options.page ?? 0}`, pageSize: options.pageSize, sort: options.sort?.field, direction: options.sort?.sort }) : undefined;
    const filter = !!options ? { filter: JSON.stringify(options.filter) } : undefined;
    return requests
        .get<QueryResult<Application>>(url, searchParams, {headers: filter, signal: abort})
      .then(result => ({ applications: result.items.map(a => new Application(a)), count: result.metaData.totalCount }))
  },
  get: (id: string) => requests
      .get<Application>(`application/${id}`)
      .then(r => new Application(r)),
  getJourney: (id: string) => requests
      .get<IJourneyEntry[]>(`application/${id}/journey`)
      .then(r => new Journey(...r)),
  update: (application: Application, specialMessage?: string, sendEmail?: boolean) => requests
      .put<Application>(`application`, application, {"X-Special-Message": removeLineBreaks(specialMessage ?? ""), "X-Send-Email": sendEmail})
      .then(r => new Application({...application, ...r})),
  getFile: (id: string, fileName: string, documentType: string) => requests
      .getFile(`application/${id}/documents/${documentType}/${fileName}`)
      .then(),
  getEmail: (email: EmailTemplate, application: Application, abort?: AbortSignal) => requests
      .get<{ body: string, subject: string }>(`application/emails/${email}`, undefined, {headers: {"X-Application": JSON.stringify(new Application({...application, notes: []}))}, signal: abort})
      .then(),
  addFile: (id: string, file: File, documentType: string) => requests
      .putFile(`application/${id}/documents/${documentType}/${file.name}`, file),
  approve: (application: Application, approvedReason: string, sendEmail: boolean) => requests
      .post<Application>(`application/approve`, application, {"X-Approved-Reason": removeLineBreaks(approvedReason), "X-Send-Email": sendEmail})
      .then(r => new Application({...application, ...r})),
  offer: (application: Application, reason: string, sendEmail: boolean) => requests
      .post<Application>(`application/offer`, application, {"X-Status-Reason": removeLineBreaks(reason), "X-Send-Email": sendEmail})
      .then(r => new Application({...application, ...r})),
  changeStatus: (application: Application, rejectedReason: string, sendEmail: boolean) => requests
      .post<Application>(`application/status`, application, {"X-Status-Reason": removeLineBreaks(rejectedReason), "X-Send-Email": sendEmail})
      .then(r => new Application({...application, ...r})),
  getFinancialInfo: (id: string) => requests
      .get(`application/${id}/financialInfo`)
      .then(),
  getEmails: (code: string) => requests
      .get<SentEmail[]>(`application/${code}/emails`)
      .then(r => r
              ?.map(e => new SentEmail(e.id, e.subject, e.message, e.createdOn))
              .sort((x, y) => x.createdOn.getDate() - y.createdOn.getDate())
          ?? []),
};

const Staff = {
  list: () => requests
      .get<Identity[]>('auth/assignableUsers/')
      .then(result => result.map(s => new Identity(s)))
};

const ScreeningResults = {
  list: (options: FilterOptions) => requests
      .get<QueryResult<IApiScreeningResult>>('screening',
          getUrlParams({pageNumber: `${options.page}`, pageSize: options.pageSize, sort: options?.sort?.field, direction: options.sort?.sort}),
          {headers: {filter: JSON.stringify(options.filter)}}
      ).then(r => ({items: r.items.map(sr => new ScreeningResult(sr)), count: r.metaData.totalCount})),
  get: (id: string) => requests
      .get<IApiScreeningResult>(`screening/${id}`)
      .then(r => new ScreeningResult(r))
};

const Payments = {
  list: (options: FilterOptions) => requests
      .get<QueryResult<Payment>>('payments',
          getUrlParams({pageNumber: `${options.page}`, pageSize: options.pageSize, sort: options?.sort?.field, direction: options.sort?.sort}),
          {headers: {filter: JSON.stringify(options.filter)}}
      ).then(r => ({payments: r.items.map(p => new Payment(p)), count: r.metaData.totalCount})),
  update: (payment: IPayment) => requests
      .put<Payment>(`payments`, payment)
      .then(r => new Payment(r)),
  delete: (payment: Payment) => requests
      .delete(`payments/${payment.id}/${payment.program}`),
  generate: (period: Period, program: string) => requests
      .getFile(`payments/${program}/${period.toString()}`),
  statistics: () => requests
      .get<IPaymentSummary>('payments/statistics')
      .then(payments => ({payments}))
};

const PaymentFiles = {
  list: (options: FilterOptions) => requests
      .get<QueryResult<PaymentFile>>('payments/files',
          getUrlParams({pageNumber: `${options.page}`, pageSize: options.pageSize, sort: options?.sort?.field, direction: options.sort?.sort}),
          {headers: {filter: JSON.stringify(options.filter)}}
      ).then(r => ({paymentsFiles: r.items.map(p => new PaymentFile(p)), count: r.metaData.totalCount})),
  getFile: (id: string) => requests
      .getFile(`payments/files/${id}`),
  addDoc: (id: string, file: File, docType: PaymentFileDocType) => requests
      .putFile(`payments/files/${id}/${docType}`, file),
  removeDoc: (id: string, fileName: string, docType: PaymentFileDocType) => requests
      .delete(`payments/files/${id}/${docType}/${fileName}`),
  downloadDoc: (id: string, fileName: string, docType: PaymentFileDocType) => requests
      .getFile(`payments/files/${id}/${docType}/${fileName}`)
      .then(),
  update: (file: PaymentFile) => requests
      .put(`payments/files`, file)
      .then()
};

const Rates = {
  list: () => requests
      .get<QueryResult<{ tenantId: string, rates: RatesCollection }>>("rates")
      .then(({items}) => new RatesCollection(items.pop()?.rates)),
  save: (rate: RateRule) => requests
      .post<RatesCollection>(`rates`, rate)
      .then(r => new RatesCollection(r))
};

const IncomeTypes = {
    list: () => requests
        .get<QueryResult<IncomeType>>("incomeTypes", getUrlParams({pageSize: 0}))
        .then(({items}) => items.map(i => new IncomeType(i))),
    save: (incomeType: IncomeType) => requests
        .post<IncomeType>(`incomeTypes`, incomeType)
        .then()
};

const DeactivationReasons = {
  list: () => requests
      .get<QueryResult<DeactivationReason>>("admin/deactivationReason")
      .then(({items}) => items.map(d => new DeactivationReason(d))),
  save: (reason: DeactivationReason) => requests
      .post<DeactivationReason>("admin/deactivationReason", reason)
      .then(),
  delete: (reasonId: string) => requests
      .delete(`admin/deactivationReason/${reasonId}`)
};

const ProgramBudgets = {
    list: (options?: FilterOptions) => requests
        .get<QueryResult<ProgramBudget>>("programBudgets",
            getUrlParams({
                pageNumber: options?.page,
                pageSize: options?.pageSize,
                sort: options?.sort?.field,
                direction: options?.sort?.sort
            }), {headers: {filter: JSON.stringify(options?.filter)}},
        )
        .then(({items}) => items.map(i => new ProgramBudget(i))),
    upsert: (programBudget: ProgramBudget) => requests
        .put<ProgramBudget>(`programBudgets/${programBudget.id}`, programBudget),
    delete: (id: string) => requests
        .delete(`programBudgets/${id}`),
}

const Announcements = {
  get: async (id: string) => requests
      .get<Announcement>(`announcements/${id}`)
      .then(),
  list: async (options: FilterOptions) => requests
      .get<QueryResult<IAnnouncement>>('announcements',
          getUrlParams({pageNumber: `${options.page}`, pageSize: options.pageSize, sort: options.sort?.field, direction: options.sort?.sort}),
          {headers: {filter: JSON.stringify(options.filter)}}
      ).then(result => ({announcements: result.items, count: result.metaData.totalCount})),
  save: async (value: Announcement) => requests
      .post<Announcement>("announcements", value)
      .then(),
  delete: (id: string, type: AnnouncementType) => requests
      .delete(`announcements/${id}/${type}`),
};

const Statistics = {
  Counts: () => requests
    .get<{ applications: IApplications; clients: IClients; }>('statistics')
    .then(counts => ({ counts })),
  ProgramProgress: () => requests
    .get<{ fiscalYear: number; progress: IProgress[]; }>('statistics/programProgress')
    .then(programProgress => ({ programProgress })),
};

const AnnualReview = {
  search: async (searchValue: string) => {
    const url = `Application/search/${searchValue}?applicationTypes=AnnualReview`;
    return requests.get<QueryResult<Application>>(url);
  },
  Create: (clientCode: string) => requests
    .post<Application>('annualReview', {clientCode})
    .then(r => new Application(r))
};

const InterimReview = {
  search: async (searchValue: string) => {
    const url = `Application/search/${searchValue}?applicationTypes=InterimReview`;
    return requests.get<QueryResult<Application>>(url);
  },
  Create: (clientCode: string) => requests
    .post<Application>('interimReview', {clientCode})
    .then(r => new Application(r))
};

const Settings = {
  get: () => requests
      .get<GeneralSettings>('admin/settings')
      .then(s => new GeneralSettings(s)),
  save: (settings: GeneralSettings) => requests
      .post<GeneralSettings>('admin/settings', settings)
      .then(s => new GeneralSettings(s))
};

const agent = {
  setUser,
  ScreeningResults,
  Applications,
  AnnualReview,
  InterimReview,
  Staff,
  ServiceTypes,
  Clients,
  Payments,
  PaymentFiles,
  Rates,
  IncomeTypes,
  DeactivationReasons,
  ProgramBudgets,
  Announcements,
  Statistics,
  Settings
}

export default agent;
export type {FilterOptions};