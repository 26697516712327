import {BankInfo} from "../application";
import {PaymentStatus} from "../enums";
import {getDateFrom} from "../dates";
import {createErrorState, IErrorState, Period} from "library";

enum PaymentType {
    Cheque = "Cheque Payment",
    EFT = "EFT Payment"
}

interface IPayment extends IAuditDetail {
    id?: string;
    disbursementId?: string;
    bankDetails: BankInfo;
    address: string;
    clientCode: string;
    clientName: string;
    scheduledDate: Date;
    paymentDate: Date | undefined;
    program: string;
    amount: number;
    status: PaymentStatus;
    note: string;
    adjustmentMade: boolean;
    clientId: string;
    period: Period;
}

class Payment implements IPayment, IErrorState<Payment> {
    readonly id?: string;
    readonly disbursementId?: string;
    readonly bankDetails: BankInfo;
    readonly address: string;
    readonly clientCode: string;
    readonly clientName: string;
    readonly scheduledDate: Date;
    readonly paymentDate: Date | undefined;
    readonly program: string;
    readonly amount: number;
    readonly status: PaymentStatus;
    readonly note: string;
    readonly adjustmentMade: boolean;
    readonly createdOn: Date;
    readonly createdBy: string;
    readonly modifiedOn: Date;
    readonly modifiedBy: string;
    readonly clientId: string;
    readonly period: Period;
	  readonly validated: boolean;

    constructor(from: Partial<IPayment>) {
        this.id = from.id;
        this.disbursementId = from.disbursementId;
        this.bankDetails = new BankInfo(from.bankDetails);
        this.address = from.address ?? "";
        this.clientCode = from.clientCode ?? "";;
        this.clientName = from.clientName ?? "";;
        this.scheduledDate = getDateFrom(from.scheduledDate);
        this.paymentDate = from.paymentDate ? getDateFrom(from.paymentDate) : undefined;
        this.program = from.program ?? "";
        this.amount = from.amount ?? 0;
        this.status = from.status ?? PaymentStatus.Upcoming;
        this.note = from.note ?? "";
        this.adjustmentMade = from.adjustmentMade ?? false;
        this.createdOn = new Date(from?.createdOn ?? new Date());
        this.createdBy = from?.createdBy ?? "";
        this.modifiedOn = new Date(from?.modifiedOn ?? new Date());
        this.modifiedBy = from?.modifiedBy ?? "";
        this.clientId = from.clientId?? "";
        this.period = new Period(this.scheduledDate.getFullYear(), this.scheduledDate.getMonth() + 1);
		    this.validated = (from as Payment)?.validated ?? false;
    }

    get type(): PaymentType {
        return this.bankDetails?.chequePayments ? PaymentType.Cheque : PaymentType.EFT;
    }

    validate() {
        return new Payment({...this, validated: true});
    }
	
	get errorState() {
		return this.validated ?
			createErrorState<Payment>({
				program: !this.program ? "Program required." : "",
				amount: !this.amount ? "Specify an amount greater than 0." : "",
                note: !this.note ? "A reason must be made with any payment adjustment." : undefined
			}) : {};
	}
}

export type {
    IPayment
};

export {
    Payment, PaymentType
}