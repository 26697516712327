import {
	PpulusColumnFilter,
	PpulusFilterItem,
	PpulusFilterValue,
	PpulusGridFilterModel,
} from "types/grid";
import { DropdownOption } from "material-react-table";
import { GridLinkOperator } from "types/grid";
import { match, P } from "ts-pattern";

class PpulusFilter {
	public filter: PpulusGridFilterModel;

	constructor(from?: PpulusFilterValue) {
		this.filter = {
			items: from?.filter(i => i.value && i.value !== "").map(f => this.GetFilterItemFrom(f)) ?? [],
			quickFilterValues: from?.map(f => f),
			quickFilterLogicOperator: GridLinkOperator.And
		};
	}

	getFilterValueFrom() {
		return this.filter as PpulusGridFilterModel ?? undefined;
	}

	getOperatorFrom(filter: PpulusColumnFilter) {
		return this.translateOperator(filter);
	}

	translateOperator(filter: PpulusColumnFilter) {
		return match(filter)
			.with(P.when(value => Array.isArray(value)), () => "arraycontains")
			.with({type: "boolean"}, () => "is")
			.with({type: "date", operatorValue: "afterOrOn"}, () => "onorafter")
			.with({type: "date", operatorValue: "beforeOrOn"}, () => "onorbefore")
			.with({operatorValue: "equals"}, () => "equals")
			.with({operatorValue: "notEquals"}, () => "notequals")
			.with({operatorValue: "empty"}, () => "isempty")
			.with({operatorValue: "notEmpty"}, () => "itnotempty")
			.with({operatorValue: "lessThan"}, () => "onorbefore")
			.with({operatorValue: "greaterThan"}, () => "onorafter")
			.with({operatorValue: "fuzzy"}, () => "contains")
			.with({operatorValue: "startsWith"}, () => "contains")
			.otherwise(() => "contains");
	}

	private GetFilterItemFrom(filter: PpulusColumnFilter): PpulusFilterItem {
		return {
			value: filter.value,
			columnField: filter.id,
			operatorValue: this.getOperatorFrom(filter)
		};
	}

	public static EnumToDropdownOptions(value: object):DropdownOption[] {
		return Object.entries(value).map(e => ({value: e[1], label: e[1]})).sort((a,b) => a.label.localeCompare(b.label));
	}

}
export {PpulusFilter};