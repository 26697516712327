import styles from "styles/application.module.scss";

import {Grid, Typography} from "@mui/material";
import {fullWidth, halfWidth, halfWidthMinusOne, PointScore, ScoreValue, UtilityScore} from "library";
import React, {FC, ReactNode} from "react";

const ScoreBreakdown: (props: {value: PointScore}) => ReactNode = ({value}) => {
	return (
		<Grid container spacing="15px" className={styles.card}>
			<Grid item {...fullWidth}>
				<Typography variant={"h3"}>Score Breakdown <span className={styles.scoreBreakdown}>{value.total}<span className={styles.text}>Calculated Score</span></span></Typography>
			</Grid>

			<Grid item {...halfWidth}>
				<ScoreDisplay label={"Discretionary Points"} value={value.discretionaryPoints} />
				<ScoreDisplay label={"Number of Dependants"} value={value.numberOfDependants} />
				<ScoreDisplay label={"Rent to Income"} value={value.rentToIncome} valuePrepend={"%"} odd />
				<ScoreDisplay label={"Utilities"} value={value.utilitiesScore} />
				<ScoreDisplay label={"Health and Safety Risk"} value={value.healthAndSafety} odd />
			</Grid>

			<Grid item {...halfWidthMinusOne}>
				<ScoreDisplay label={"Impacts to personal well-being"} value={value.personalWellBeing} />
				<ScoreDisplay label={"Target Population"} value={value.targetPopulations} odd />
			</Grid>
		</Grid>	
	);
};

const ScoreDisplay: FC<{label: string, value: ScoreValue<any>, valuePrepend?: string, odd?: boolean}> = ({label, value, valuePrepend, odd}) => {
	const valueIsArray = typeof value.value === typeof([]);

	const display = (value: any) => {
		if (value instanceof UtilityScore)
			return (
				<Grid container>
					<Grid item xs={9} sm={9} md={9}>{value.label}</Grid>
					<Grid item xs={2} sm={2} md={2}>{value.value}</Grid>
				</Grid>
			);

		if (typeof value === typeof [])
			return "";

		if (typeof value === typeof true)
			return value ? "Yes" : "No";

		if (typeof value === typeof (1)) {
			const percentageValue = valuePrepend === "%" ? value * 100 : value;
			return Number.isInteger(value) ? `${percentageValue}` : Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(percentageValue);
		}

		return `${value ?? ""}`;
	}

	return (
		<Grid container className={`${styles.scoreRow} ${odd ? styles.odd : styles.even}`}>
			<Grid item xs={9} sm={9} md={9}>{label}</Grid>
			<Grid item xs={2} sm={2} md={2}>{`${display(value.value)}${value.value !== undefined ? valuePrepend ?? "" : ""}`}</Grid>
			{!!value.score && <Grid item xs={1} sm={1} md={1} className={styles.scoreIncrease}>+{value.score}</Grid>}
			{valueIsArray && (value.value as []).map((v, i) => <Grid key={`targetPopulation-${i}`} item className={styles.targetPopulation} {...fullWidth}>{display(v)}</Grid>)}
		</Grid>
	);
};

export {
	ScoreBreakdown
};