interface IOfferInfo {
	onOfferDate?: Date;
	offerAcceptanceDueDate?: Date;
	isOfferAccepted?: boolean;
	offerAcceptDeclineDate?: Date;
}

class OfferInfo implements IOfferInfo {
	readonly onOfferDate?: Date;
	readonly offerAcceptanceDueDate?: Date;
	readonly offerAcceptDeclineDate?: Date;

	constructor(from?: Partial<IOfferInfo>) {
		this.onOfferDate = from?.onOfferDate;
		this.offerAcceptanceDueDate = from?.offerAcceptanceDueDate;
		this.offerAcceptDeclineDate = from?.offerAcceptDeclineDate;
	}
}

export type { IOfferInfo };
export { OfferInfo };