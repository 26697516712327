import {createSlice} from "@reduxjs/toolkit";
import {SentEmail} from "library";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";

const getApplicationEmails = createAppAsyncThunk("applicationEmails/get", async (application: string, {getState}) => {
	const {applicationEmails: {applicationCode, items}} = getState();

	if (applicationCode === application)
		return items;

	return await agent.Applications.getEmails(application);
});

const applicationEmails = createSlice({
	name: "applicationEmails",
	initialState: {loading: false, applicationCode: "", items: new Array<SentEmail>()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getApplicationEmails.pending, () => ({loading: true, applicationCode: "", items: []}));
		builder.addCase(getApplicationEmails.fulfilled, (_, {payload, meta: {arg}}) => ({loading: false, applicationCode: arg, items: payload}));
	}
});

export default applicationEmails.reducer;

export {getApplicationEmails};