import {createErrorState, ErrorState, IErrorState} from "library/common";
import {getDateFrom, isValidDate} from "library/dates";
import {ApplicationType} from "library/enums";

export class ApproveApplicationState implements IErrorState<ApproveApplicationState> {
    readonly type: ApplicationType;
    readonly program?: string;
    readonly clientCode?: string;
    readonly specialMessage: string;
    readonly notes: string;
    readonly start: Date;
    readonly amount?: number;
    readonly sendEmail: boolean;
    protected readonly validated: boolean;

    constructor(from?: Partial<ApproveApplicationState>) {
        this.type = from?.type ?? ApplicationType.Standard;
        this.program = from?.program;
        this.clientCode = from?.clientCode;
        this.specialMessage = from?.specialMessage ?? "";
        this.notes = from?.notes ?? "";
        this.start = getDateFrom(from?.start);
        this.amount = from?.amount;
        this.sendEmail = from?.sendEmail ?? true;
        this.validated = (from as ApproveApplicationState)?.validated ?? false;
    }

    validate(): ApproveApplicationState {
        return new ApproveApplicationState({ ...this, validated: true });
    }

    get errorState() {
        return this.validated ? createErrorState<ApproveApplicationState>(this.errors()) : {};
    }

    protected errors(): Partial<Record<keyof ApproveApplicationState, string>> {
        return {
            start: !this.start
                ? "Required"
                : isValidDate(this.start)
                    ? ""
                    : "Please use date format YYYY-MM-DD",
            amount: !this.amount || this.amount < 0 ? "Amount must be greater than 0" : "",
            program: !this.program ? "Required" : ""
        };
    }
}

export class OfferApplicationState extends ApproveApplicationState implements IErrorState<OfferApplicationState> {
    readonly offerAcceptanceDueDate?: Date;

    constructor(from?: Partial<OfferApplicationState>) {
        super(from);

        this.offerAcceptanceDueDate = from?.offerAcceptanceDueDate;
    }

    validate(): OfferApplicationState {
        return new OfferApplicationState({ ...this, validated: true });
    }

    get errorState(): ErrorState<Partial<OfferApplicationState>> {
        return this.validated
            ? createErrorState<OfferApplicationState>({
                ...this.errors(),
                offerAcceptanceDueDate: !this.offerAcceptanceDueDate
                    ? "Required"
                    : isValidDate(this.offerAcceptanceDueDate)
                        ? ""
                        : "Please use date format YYYY-MM-DD",
            })
            : {};
    }
}