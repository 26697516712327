import { GridSortDirection, GridSortItem } from "@mui/x-data-grid";
import { MRT_SortingState } from "material-react-table";

export class GridHelper {
	static SortFrom(sortingState: MRT_SortingState):GridSortItem {
		// Only single field sorting allowed at the moment.
		if (Array.isArray(sortingState) && sortingState.length > 0) {
			const firstSort = sortingState[0];
			const toSort: GridSortDirection = firstSort?.desc ? "desc" : "asc";
			return {field: firstSort?.id, sort: toSort};
		}
		return {field: "", sort: undefined};
	}

	static SortTo(sortItem: GridSortItem | undefined):MRT_SortingState {
		if (!sortItem || sortItem.field === undefined) return [];
		return [{id: sortItem.field, desc: sortItem.sort === "desc"}];
	}
}