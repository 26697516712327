import styles from "styles/application.module.scss";

import { IconBrandTelegram, IconCircleX } from "@tabler/icons";
import React, { useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ApplicationContent, ApplicationStatus, ApplicationType, ClientStatus, EmailTemplate, OfferState, ProgramApproval } from "library";
import { PpulusLoader, TextArea } from "components";
import { EmailPreview } from "./EmailPreview";

type StatusTypes = ApplicationStatus | ClientStatus | "Extend" | "On Notice";

interface IStatusDialogProps<T extends StatusTypes> {
    status: T;
    description: string | JSX.Element;
    content: string | JSX.Element;
    applicationType?: ApplicationType;
    program?: ProgramApproval;
    onCancel: () => void;
    onProceed: (status: T, specialMessage: string, notes: string, sendEmail: boolean) => void;
    hideEmail?: boolean;
}

interface IEmailTemplate {
    template: EmailTemplate;
    buttonText: string;
}

const EmailMapping: Partial<Record<StatusTypes, IEmailTemplate>> = {
    [ApplicationStatus.Submitted]: {template: EmailTemplate.SubmitApplication, buttonText: "Submit"},
    [ApplicationStatus.Rejected]: {template: EmailTemplate.RejectApplication, buttonText: "Reject"},
    [ApplicationStatus.Cancelled]: {template: EmailTemplate.CancelApplication, buttonText: "Cancel"},
    [ApplicationStatus.OnHold]: {template: EmailTemplate.SuspendApplication, buttonText: "Suspend"},
    [ApplicationStatus.Eligible]: {template: EmailTemplate.EligibleApplication, buttonText: "Eligible"},
    [ClientStatus.Inactive]: {template: EmailTemplate.DeactivateClient, buttonText: "Deactivate"},
    [ClientStatus.Suspended]: {template: EmailTemplate.SuspendClient, buttonText: "Suspend"},
    [ClientStatus.Active]: {template: EmailTemplate.ActivateClient, buttonText: "Activate"},
    // eslint-disable-next-line
    ["Extend"]: {template: EmailTemplate.ExtendClient, buttonText: "Extend (+1 month)"},
    // eslint-disable-next-line
    ["On Notice"]: {template: EmailTemplate.OnNoticeClient, buttonText: "On Notice (Shorten Program)"},
};

const EmailTemplateOverride = (status: StatusTypes, type: ApplicationType | undefined, template: IEmailTemplate) => {
    if (status === ApplicationStatus.Cancelled && type === ApplicationType.InterimReview)
        return {...template, template: EmailTemplate.CancelInterimReview};
    return template;
};

const StatusDialog = <T extends StatusTypes>({status, description, content, applicationType, program, onCancel, onProceed, hideEmail}: IStatusDialogProps<T>) => {
    const emailTemplate = useMemo(() => EmailTemplateOverride(status, applicationType, EmailMapping[status]!), [status, applicationType]);
    const [state, setState] = useState({specialMessage: "", notes: "", sendEmail: true});
    const [processing, setProcessing] = useState(false);

    const buttonText = !!applicationType && [ApplicationStatus.Rejected, ApplicationStatus.Cancelled, ApplicationStatus.OnHold].some(s => s === status)
        ? `${emailTemplate.buttonText} ${ApplicationContent[applicationType]!.heading}` : emailTemplate.buttonText;

    const set = (newValue: Partial<{ specialMessage: string, notes: string, sendEmail: boolean }>) => setState(s => ({...s, ...newValue}));

    const proceed = () => {
        setProcessing(true);
        onProceed(status, state.specialMessage, state.notes, state.sendEmail && !hideEmail);
    };

    return (
        <Grid container className={styles.modalContainer}>
            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <Typography variant={"h3"}>{buttonText}</Typography>
                <br/>
                {description}
            </div>

            <div className={styles.fullWidth}>
                {content}
            </div>

          {!hideEmail && <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <EmailPreview emailKey={{emailTemplate: emailTemplate.template, program, override: !!program}} dependencies={[program]} disabled={processing} onNoteChange={v => set({specialMessage: v})} onSendEmailChanged={v => set({sendEmail: v})}/>
            </div>}

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <TextArea label={"Notes"} value={state.notes} disabled={processing} onChange={v => set({notes: v})} rows={5}/>
            </div>

            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                {processing
                    ? <div className={styles.loader}><PpulusLoader/></div>
                    : <Button variant={"contained"} disabled={processing} startIcon={<IconBrandTelegram/>} onClick={proceed}>{buttonText}</Button>}
            </div>
        </Grid>
    );
};

type OfferDialogProps = {
    offerState: OfferState;
    onCancel: () => void;
    onProceed: () => void;
}

const OfferDialog = ({offerState, onCancel, onProceed}: OfferDialogProps) => {
    const [processing, setProcessing] = useState(false);

    const action = offerState === OfferState.Accepted ? "accept" : offerState === OfferState.Rescind ? "rescind" : "";

    const proceed = () => {
        setProcessing(true);
        onProceed();
    };

	return (
		<Grid container className={styles.modalContainer}>
            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <Typography variant={"h3"}>Are you sure you wish to {action} the offer?</Typography>
            </div>

            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                {processing
                    ? <div className={styles.loader}><PpulusLoader/></div>
                    : <Button variant={"contained"} disabled={processing} startIcon={<IconBrandTelegram/>} onClick={proceed}>Yes</Button>}
            </div>
        </Grid>
	);
};

export {
    StatusDialog,
    OfferDialog
};