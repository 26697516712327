import styles from "styles/gridTable.module.scss";

import { Button, Grid } from "@mui/material";
import {PpulusRoutes} from "library";
import React, { FC, useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "store";
import {
	getClients,
	myClients,
	setClientFilter,
	setClientPage,
	setClientSort,
} from "store/slices/client";

import GridTable, { GridTableProps } from "components/GridTable";
import { ClientRow } from "./ClientRow";
import { PpulusColumn, PpulusGridFilterModel } from "types/grid";

const ClientList: FC<{ key: string, mine?: boolean }> = ({key, mine}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {loading, items: clients, page, pageSize, count, sort} = useSelector(state => state.clients);

	const staticFilter = useMemo(() => ({
		items: [
			...(mine ? [{ columnField: myClients, value: mine, operatorValue: "equals" }] : [])
		]
	}), [mine]);

	useEffect(() => {
		dispatch(getClients({filter: staticFilter, page: page, pageSize: 15, sort: {field: "lastName", sort: "desc"}}));
	}, [dispatch, mine, page, staticFilter]);

	const viewRowItem = (id: string) => navigate(`${PpulusRoutes.Clients}/${id}`);

	const onFilter = useCallback((model: PpulusGridFilterModel) => {
		dispatch(setClientFilter({items: model.items}));
	}, [dispatch]);

	const columns: PpulusColumn<ClientRow>[] = [
		...ClientRow.Columns,
		{field: null, header: null, renderCell: row => <Button variant={"text"} className={styles.compactButton} onClick={() => viewRowItem(row.id)}>View</Button>}
	];

	const gridTableProps: GridTableProps<ClientRow> = {
		exportCsvEnabled: true,
		datasource: clients.map(ClientRow.From),
		loading,
		count,
		initialPageSize: pageSize ?? 15,
		page: page ?? 0,
		exportFileNamePrefix: "ClientList",
		onRowDoubleClick: row => viewRowItem(row.id),
		onFilter: onFilter,
		onPage: (page, pageSize) => dispatch(setClientPage({page, pageSize})),
		onSort: sort => dispatch(setClientSort(sort)),
		defaultFilterValue: [],
		columns: columns,
		userFiltered: false,
		sort: sort ?? {field: "lastName", sort: "asc"},
		pageSizeOptionsEnabled: true,
	};

	return (
		<Grid container>
			<Grid key={key} item width={"100%"}>
				<GridTable {...gridTableProps} />
			</Grid>
		</Grid>
	);
};

export {
	ClientList
};