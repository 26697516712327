import clientStyles from "styles/client.module.scss";

import { Button, ButtonGroup, Card, Modal, Typography } from "@mui/material";
import { DateInput, FormValueDisplay, StatusDialog } from "components";
import { monthEndDate, Client, Note, ProgramApproval } from "library";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dispatch } from "store";
import ClientProgramRow from "./Client.Program.Row";
import { PpulusColumn } from "types/grid";
import GridTable, { GridTableProps } from "components/GridTable";
import { setClient } from "store/slices";

type ClientProgramsProps = {
	clientValue: Client | undefined;
	programs: ProgramApproval[] | undefined;
	readonly: boolean;
	setClientValue: (client: Client | undefined) => void;
	onChange: (programs: ProgramApproval[] | undefined) => Promise<void>;
}
type ProgramDialogType = "Extend" | "On Notice";
type ProcessDialog = {
	program: ProgramApproval;
	dialog: ProgramDialogType;
}

const ClientPrograms = ({ clientValue, readonly, setClientValue, programs, onChange }: ClientProgramsProps) => {
	const [editingProgram, setEditingProgram] = useState<ProgramApproval>();
	const [processDialog, setProcessDialog] = useState<ProcessDialog>();
	const [processing, setProcessing] = useState(false);

	const onDelete = useCallback(async (program: ProgramApproval) => {
		if (readonly) return;
		
		setProcessing(true);
		onChange(programs?.filter(p => p.name !== program.name && p.endDate !== program.endDate)).finally(() => setProcessing(false));
	}, [onChange, programs, readonly]);

	const onSave = (original: ProgramApproval, newValue: ProgramApproval) => {
		if (readonly) return;

		setProcessing(true);
		onChange(programs?.map(p => p === original ? newValue : p)).finally(() => setProcessing(false));
		setEditingProgram(undefined);
	};

	const onOpenProcessDialog = useCallback((dialog: ProgramDialogType, button: any) => {
		const program: ProgramApproval = JSON.parse(button.currentTarget.dataset.program);
		const endDate = monthEndDate(program.endDate, dialog === "Extend" ? 1 : -1);
		setProcessDialog({program: {...program, endDate}, dialog});
	}, [setProcessDialog]);

	const closeProcessDialog = useCallback((_: any = {}, reason = "") => {
		if (reason) return;
		setProcessDialog(undefined);
	}, [setProcessDialog]);

	const adjustProgramEnd = useCallback((message: string, notes: string, sendEmail: boolean) => {
		if (readonly) return;

		dispatch(setClient({
			clientValue: {
				programs: clientValue?.programs.map(p => p.isActive && p.name === processDialog?.program.name ? {...p, endDate: processDialog?.program.endDate} : p) ?? []
			}
			, message
			, sendEmail
			, notes: [new Note({message: notes, subject: `Client ${processDialog?.dialog}`, internal: true})]
			, refreshPayments: true
		}))
			.unwrap()
			.then(setClientValue)
			.finally(closeProcessDialog);
	}, [clientValue, processDialog, closeProcessDialog, setClientValue, readonly]);

	const onNoticeContent = useCallback((processDialog: ProcessDialog) => (
		<div className={`${clientStyles.onNotice}`}>
			<DateInput label={"Revised Program End Date"} value={processDialog.program.endDate ?? new Date()}
				onChange={v => setProcessDialog({...processDialog, program: {...processDialog.program, endDate: v ?? new Date()}})}/>
		</div>
	), [setProcessDialog]);

	const Dialog: Record<ProgramDialogType, JSX.Element> | undefined = useMemo(() => (processDialog && {
		// eslint-disable-next-line
		["Extend"]: <StatusDialog status={"Extend"}
								  description={"This will provide the recipient with an extra month of their program benefit. Program end date will be adjusted to exactly 1 month into the future and an additional disbursement will be created for the recipient."}
								  content={""}
								  onCancel={closeProcessDialog} onProceed={(_, specialMessage, notes, sendEmail) => adjustProgramEnd(specialMessage, notes, sendEmail)}/>,
		// eslint-disable-next-line
		["On Notice"]: <StatusDialog status={"On Notice"}
			description={"This will shorten the recipient's current program benefit and will cancel any planned disbursements that were originally scheduled up to the new program end date."}
			content={onNoticeContent(processDialog)}
			program={processDialog.program}
			onCancel={closeProcessDialog} onProceed={(_, specialMessage, notes, sendEmail) => adjustProgramEnd(specialMessage, notes, sendEmail)}/>,
	}), [processDialog, closeProcessDialog, adjustProgramEnd, onNoticeContent]);

	const columns: PpulusColumn<ClientProgramRow>[] = [
		...ClientProgramRow.Columns,
		...([
			{
			header: null, renderCell: row =>
					<ButtonGroup variant="text" color="primary">
						<Button title={"Edit"} disabled={readonly} onClick={() => setEditingProgram(row.program)}>Edit</Button>
						{row.active && <>				
						<Button title={"On Notice"} disabled={readonly} data-program={JSON.stringify(row)} onClick={(e) => onOpenProcessDialog("On Notice", e)}>
							On Notice
						</Button>
						<Button title={"Extend (+1)"} disabled={readonly} data-program={JSON.stringify(row)} onClick={(e) => onOpenProcessDialog("Extend", e)}>
							Extend (+1)
						</Button>
						</>}
						<Button title={"Delete"} disabled={readonly} onClick={() => window.confirm("Are you sure you want to delete this program?") && onDelete({...row, amount: 0})}>
							Delete
						</Button>
				</ButtonGroup>
			} as PpulusColumn<ClientProgramRow>
		])
	];

	const rows = useMemo(() => programs?.map(ClientProgramRow.From) ?? [], [programs]);

	const gridTableProps: GridTableProps<ClientProgramRow> = {
		exportCsvEnabled: true,
		datasource: rows,
		count: rows.length,
		initialPageSize: 15,
		page: 0,
		exportFileNamePrefix: "ClientProgramList",
		defaultFilterValue: [],
		columns: columns,
		userFiltered: false,
		isFilterable: false,
		loading: processing
	};

	return (
		<Card>
			{editingProgram
				? <ProgramForm programToEdit={editingProgram} readonly={readonly} onChange={onSave} onCancel={() => setEditingProgram(undefined)}/>
				: 
				<>
					<GridTable {...gridTableProps} />
					<Modal open={!!processDialog} onClose={closeProcessDialog}>
						{processDialog?.dialog ? Dialog![processDialog.dialog] : <></>}
					</Modal>
				</>
			}
		</Card>
	);
};

type ProgramFormProps = {
    programToEdit: ProgramApproval;
		readonly: boolean;
    onChange: (original: ProgramApproval, newValue: ProgramApproval) => void;
    onCancel: () => void;
};

const ProgramForm = ({ programToEdit, readonly, onChange, onCancel }: ProgramFormProps) => {
	const [program, setProgram] = useState<ProgramApproval>(programToEdit);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		setProgram(programToEdit);
	}, [programToEdit]);

	const set = async (value: Partial<ProgramApproval>) => setProgram({ ...program, ...value });

	const onSave = () => {
		if (program.startDate >= program.endDate) {
			setError("End Date must be greater than Start Date.");
			return;
		}
		
		onChange(programToEdit, program);
	};

	return (
		<div className={clientStyles.programForm}>
			<Typography variant={"h3"}>Edit Program</Typography>
			<div className={clientStyles.programRow}>
				<FormValueDisplay label={"Program"} value={(program.name)} />
				<DateInput label={"Start Date"} value={program.startDate} disabled={readonly} onChange={v => set({ startDate: v ?? new Date() })} />
				<DateInput label={"End Date"} value={program.endDate} disabled={readonly} error={error} onChange={v => set({ endDate: v ?? new Date() })} />
			</div>
			<div className={clientStyles.buttonGroup}>
				<Button className={clientStyles.button} variant={"outlined"} onClick={onCancel}>Cancel</Button>
				<Button disabled={readonly} className={clientStyles.button} variant={"contained"} onClick={onSave}>Save</Button>
			</div>
		</div>
	);
};

export {
	ClientPrograms
};