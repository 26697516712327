import styles from "styles/gridTable.module.scss";

import { FC, useCallback, useEffect, useMemo } from "react";
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "store";
import { getScreeningResults, setScreeningFilter, setScreeningPage, setScreeningSort } from "store/slices/screening";
import { defaultFilterOption } from "library";
import {useNavigate} from "react-router-dom";
import GridTable, { GridTableProps } from "components/GridTable";
import {ScreeningRow} from "./ScreeningRow";
import { PpulusGridFilterModel, PpulusGridSortItem } from "types/grid";

const ScreeningList: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {loading, items, count, page, pageSize, sort} = useSelector(state => state.screening);

	const initialSort: PpulusGridSortItem = useMemo(() => (
		{ sort: "asc", field: "householdIncome"}
	), []);

	useEffect(() => {
		dispatch(getScreeningResults({...defaultFilterOption, sort: initialSort }));
	}, [dispatch, initialSort]);

	const onPage = useCallback((page: number, pageSize: number) => {
		dispatch(setScreeningPage({page, pageSize}));
	}, [dispatch]);

	const onSort = useCallback((model: PpulusGridSortItem) => {
		dispatch(setScreeningSort(model));
	}, [dispatch]);

	const onFilter = useCallback((model: PpulusGridFilterModel) => {
		dispatch(setScreeningFilter({items: model.items}));
	}, [dispatch]);

	const viewRowItem = (id: string) => navigate(`${id}`, {relative: "path"});

	const gridTableProps: GridTableProps<ScreeningRow> = {
		isFilterable: true,
		exportCsvEnabled: true,
		datasource: items.map(ScreeningRow.From),
		count,
		loading,
		initialPageSize: pageSize ?? 15,
		page: page ?? 0,
		exportFileNamePrefix: "ScreeningList",
		onRowDoubleClick: (r) => viewRowItem(r.id),
		onFilter: onFilter,
		onPage: onPage,
		onSort: onSort,
		defaultFilterValue: [],
		columns: ScreeningRow.Columns,
		userFiltered: false,
		sort: sort ?? initialSort
	};

	return (
		<Grid container columnSpacing={4}>
			<Grid item width={"100%"} className={styles.content}>
				<GridTable {...gridTableProps}/>
			</Grid>
		</Grid>
	);
};

export {
	ScreeningList
};
