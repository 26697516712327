import styles from "styles/application.module.scss";

import {Note} from "library";
import {ReactNode} from "react";
import {Grid} from "@mui/material";
import {NotesList} from "components";
import {viewOrDownloadApplicationDocument} from "store/slices";
import {useDispatch} from "store";

type ApplicationNotesProps = {
    readonly: boolean;
    value: Note[];
    onChange: (value: Note[]) => Promise<void>;
}

const ApplicationNotes: (props: ApplicationNotesProps) => ReactNode = ({value, readonly, onChange}) => {
    const dispatch = useDispatch();

    return (
        <Grid container className={styles.card}>
            <NotesList notes={value} readonly={readonly} onChange={onChange} onDownload={async (fileName) => await dispatch(viewOrDownloadApplicationDocument({fileName, documentType: "notes"}))}/>
        </Grid>
    );
};

export {
    ApplicationNotes
}