import {createSlice} from "@reduxjs/toolkit";
import agent, {FilterOptions} from "api/agent";
import {ScreeningResult} from "library/screening/screeningResult";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {RootState} from "store";
import {createAppAsyncThunk} from "./asyncThunk";
import { PpulusPagination } from "types/grid";

const getCurrentOptions = (getState: () => RootState) => {
	const {screening: {filter, page, pageSize, sort}} = getState();
	return {filter, page, pageSize, sort};
};

const getScreeningResults = createAppAsyncThunk("screening/load", async (options: FilterOptions, {getState}) => {
	return await agent.ScreeningResults.list({...getCurrentOptions(getState), ...options});
});

const getScreeningResult = createAppAsyncThunk("screening/get", async (id: string, {getState}) => {
	const {screening: {items}} = getState() as {screening: {items: Array<ScreeningResult>}};
	return items.find(i => i.id === id) ?? await agent.ScreeningResults.get(id);
});

const setScreeningPage = createAppAsyncThunk("screening/setPage", async (pagination:PpulusPagination , {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), ...{page: pagination.page, pageSize: pagination.pageSize}};
	await dispatch(getScreeningResults(options));
	return options;
});

const setScreeningPageSize = createAppAsyncThunk("screening/setPage", async (page: number, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), page};
	await dispatch(getScreeningResults(options));
	return options;
});

const setScreeningSort = createAppAsyncThunk("screening/sort", async (sort: GridSortItem | undefined, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), sort};
	await dispatch(getScreeningResults(options));
	return options;
});

const setScreeningFilter = createAppAsyncThunk("screening/filter", async (filter: GridFilterModel, {dispatch, getState}) => {
	const options = {...getCurrentOptions(getState), filter};
	await dispatch(getScreeningResults(options));
	return options;
});

const initialState: FilterOptions & { loading: boolean, items: ScreeningResult[], count: number } = {
	page: 0,
	pageSize: 15,
	loading: true,
	items: [],
	count: 0
};

const screening = createSlice({
	name: "screening",
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(getScreeningResults.pending, (state) => ({...state, loading: true, items: []}));
		builder.addCase(getScreeningResults.rejected, (_, {error}) => console.log(error));
		builder.addCase(getScreeningResults.fulfilled, (state, {payload}) => ({...state, loading: false, ...payload}));
		builder.addCase(getScreeningResult.pending, (state) => ({...state, loading: true}));
		builder.addCase(getScreeningResult.fulfilled, (state) => ({...state, loading: false}));
		builder.addCase(setScreeningPage.fulfilled, (state, {payload}) => ({...state, ...payload}));
		builder.addCase(setScreeningSort.fulfilled, (state, {payload}) => ({...state, ...payload}));
		builder.addCase(setScreeningFilter.fulfilled, (state, {payload}) => ({...state, ...payload}));
	}
});

export default screening.reducer;

export {getScreeningResults, getScreeningResult, setScreeningPage, setScreeningPageSize, setScreeningSort, setScreeningFilter};
