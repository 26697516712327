import styles from "styles/gridTable.module.scss";

import {Button, Grid} from "@mui/material";
import { ApplicationContent, ApplicationStatus, ApplicationType, fullWidth } from "library";
import React, {FC, useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "store";
import {getApplications, myApplications, setApplicationFilter, setApplicationPage, setApplicationSort} from "store/slices/applications";
import {AnnualReviewRow} from "./AnnualReview/AnnualReviewRow";
import {ApplicationRow} from "./ApplicationRow";
import {InterimReviewRow} from "./InterimReview";
import GridTable, { GridTableProps } from "components/GridTable";
import { PpulusColumn, PpulusGridFilterModel, PpulusGridSortItem } from "types/grid";
import { OfferState } from "library";

const ApplicationsList: FC<{ type: ApplicationType, status?: ApplicationStatus, offerState?: OfferState, mine?: boolean, key: string }> = ({type, status, offerState, mine, key}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {items: applications, loading, count, page, pageSize, sort} = useSelector(state => state.applications);

	const onOfferFilter = useMemo(() =>
		status !== ApplicationStatus.Eligible
			? []
			: [
				{ columnField: "onOfferDate", value: "", operatorValue: offerState ? "Defined" : "NotDefined" },
				{ columnField: "isOfferAccepted", value: "true", operatorValue: offerState === OfferState.Accepted ? "Equals" : "NotDefined" },
			]
	, [offerState, status]);

	const staticFilter = useMemo(() => ({
		items: [
			{columnField: "type", value: type, operatorValue: "equals"},
			...(status ? [{columnField: "status", value: status, operatorValue: "equals"}] : []),
			...(mine ? [{columnField: myApplications, value: mine, operatorValue: "equals"}] : []),
			...onOfferFilter
		]
	}), [type, status, mine, onOfferFilter]);

	useEffect(() => {
		dispatch(getApplications({
			filter: staticFilter,
			sort: undefined,
			page: 0,
			pageSize: 15
		}));
		// eslint-disable-next-line
	}, [type, status, mine]);

	const onPage = useCallback((newPage: number, pageSize: number) => {
		dispatch(setApplicationPage({ page: newPage, pageSize}));
	}, [dispatch]);

	const onSort = useCallback((model: PpulusGridSortItem) => {
		dispatch(setApplicationSort(model));
	}, [dispatch]);

	const onFilter = useCallback((model: PpulusGridFilterModel) => {
		dispatch(setApplicationFilter({items: [...staticFilter.items, ...model.items]}));
	}, [dispatch, staticFilter]);

	const viewRowItem = (id: string) => {
		navigate(`/pages/${ApplicationContent[type].path}/${id}`);
	};

	const ColumnsForStandard = offerState === OfferState.OnOffer
		? ApplicationRow.OnOfferColumns
		: offerState === OfferState.Accepted || (!status && !mine)
			? ApplicationRow.AcceptedColumns
			: ApplicationRow.Columns;

	const applicationTypeColumns: Record<ApplicationType, PpulusColumn<any>[]> = {
		[ApplicationType.Standard]: Object.values(ColumnsForStandard),
		[ApplicationType.AnnualReview]: Object.values(AnnualReviewRow.Columns),
		[ApplicationType.InterimReview]: Object.values(InterimReviewRow.Columns),
	};

	const columns: PpulusColumn<ApplicationRow>[] = [
		...Object.values(applicationTypeColumns[type]),
		{field: null, header: null, renderCell: row => <Button variant={"text"} className={styles.compactButton} onClick={() => viewRowItem(row.id)}>View</Button>}
	];

	const rows = useMemo(() => {
		switch (type) {
			case ApplicationType.Standard:
				return applications.map(ApplicationRow.From).filter(a => a);
			case ApplicationType.AnnualReview:
				return applications.map(AnnualReviewRow.From).filter(a => a);
			case ApplicationType.InterimReview:
				return applications.map(InterimReviewRow.From).filter(a => a);
			default:
				return [].map(ApplicationRow.From).filter(a => a);
		}
	}, [applications, type]);

	const gridTableProps: GridTableProps<any> = {
		exportCsvEnabled: true,
		datasource: rows,
		count,
		loading,
		initialPageSize: pageSize ?? 15,
		page: page ?? 0,
		exportFileNamePrefix: "ApplicationList",
		onRowDoubleClick: row => viewRowItem(row.id),
		onFilter: onFilter,
		onPage: onPage,
		onSort: onSort,
		defaultFilterValue: [],
		columns: columns,
		userFiltered: false,
		sort: sort
	};

	return (
		<Grid container columnSpacing={4}>
			<Grid item {...fullWidth}>
				<GridTable key={key} {...gridTableProps}/>
			</Grid>
		</Grid>
	);
};

export {
	ApplicationsList
};