import { DateDisplay, getDateFrom, ProgramApproval } from "library";
import { IconCheck, IconX } from "@tabler/icons";
import { PpulusColumn } from "types/grid";

class ClientProgramRow {
	public static Columns: PpulusColumn<ClientProgramRow>[] = [
		{field: "name", header: "Program", renderCell: row => row.name},
		{field: "startDate", header: "Start Date", renderCell: row => DateDisplay.Standard(row.startDate)},
		{field: "endDate", header: "End Date", renderCell: row => DateDisplay.Standard(row.endDate)},
		{field: "active", header: "Active", renderCell: row => row.active ? <IconCheck color={"green"} size={"17px"} /> : <IconX color={"red"} size={"17px"} /> }
	];

	constructor(readonly program: ProgramApproval, readonly name: string, readonly startDate: Date, readonly endDate: Date, readonly active: boolean) {
	}

	public static From(program: ProgramApproval): ClientProgramRow {
		return new ClientProgramRow(
			program,
			program.name,
			getDateFrom(program.startDate),
			getDateFrom(program.endDate),
			program.isActive ?? false
		);
	}
}

export default ClientProgramRow; 