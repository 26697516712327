import styles from "styles/application.module.scss";
import approveStyles from "styles/applicationApprove.module.scss";

import { Button, ButtonGroup, FormControl, Grid, Input, InputAdornment, InputLabel, Stack, Typography } from "@mui/material";
import { IconCircleX, IconFileCheck, IconInfoCircle } from "@tabler/icons";
import { DateInput, PpulusLoader, TextArea } from "components";
import { EmailPreview } from "components/EmailPreview";
import { Application, ApplicationContent, ApproveApplicationState, DateDisplay, EmailTemplate, fullWidth, hasError, oneThirdWidth } from "library";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "store";
import { getClient } from "store/slices";

interface IApproveInterimReviewProps {
    value: Application;
    onCancel: () => void;
    onApprove: (value: ApproveApplicationState) => Promise<void>;
}

const ApproveInterimReview: (props: IApproveInterimReviewProps) => ReactNode = ({value, onCancel, onApprove}) => {
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState<ApproveApplicationState>(new ApproveApplicationState({
        type: value.type,
        clientCode: value.clientCode,
        program: [...(value.programs ?? [])].shift() ?? "",
        specialMessage: "",
        notes: "",
        start: new Date(),
        sendEmail: true,
        amount: value.amounts[[...(value.programs ?? [])].shift() ?? ""]?.amount
    }));

    const emailKey = useMemo(() => ({
        emailTemplate: EmailTemplate.ApproveInterimReview,
        override: true,
        application: {
            approvedProgram: {
                name: state.program!,
                startDate: state.start,
                amount: state.amount ?? 0,
                endDate: new Date()
            }
        }
    }), [state]);

    const set = (newValue: Partial<ApproveApplicationState>) => setState(new ApproveApplicationState({...state, ...newValue}));

    const approve = () => {
        const validated = state.validate();
        set(validated);
        if (hasError(validated) || !onApprove) return;

        setProcessing(true);
        onApprove(state).finally(() => setProcessing(false));
    };

    const heading = ApplicationContent[value.type].heading;
    
    return (
        <Grid container className={styles.modalContainer}>
            <Grid item {...fullWidth}>
                <div className={styles.modalText}>
                    <Typography variant={"h3"}>Approve {heading}</Typography>
                    <br/>
                    An email will be sent out to <a href={`mailto: ${value.applicant.email?.address}`}>{value.applicant.email?.address ?? ""}</a> notifying the client that their {heading.toLowerCase()} (reported change(s)) has been approved and implemented. 
                    Please complete the following details to complete the processing of this {heading.toLowerCase()}.
                </div>
            </Grid>
            <Grid item {...fullWidth}>
                <div className={styles.modalText}>
                    <div className={approveStyles.header}>Client file will be updated</div>
                    <p>Data from this {heading.toLowerCase()} will be used to update the Client's file.</p>

                    <Grid {...fullWidth} container>
                        <Grid item {...oneThirdWidth}>
                            <DateInput label={"Disbursements Update Starting Date"} value={state.start} disabled={processing} error={state.errorState.start} onChange={v => set({ start: v ?? new Date() })} />
                        </Grid>
                        <Grid item {...oneThirdWidth}>
                            <FormControl variant="standard">
                                <InputLabel>Monthly Subsidy Amount</InputLabel>
                                <Input type={"number"}
                                    disabled={processing}
                                    value={state.amount ?? ""}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    onChange={(e) => set({ amount: (Number(e.target.value)) })} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <EmailPreview emailKey={emailKey} disabled={processing} dependencies={[state.amount, state.start]} onNoteChange={v => set({specialMessage: v})} onSendEmailChanged={v => set({sendEmail: v})}/>
            </div>

            <div className={`${styles.modalText} ${styles.fullWidth}`}>
                <Stack alignItems="center" direction="row" gap={1} className={approveStyles.note}>
                    <IconInfoCircle className={approveStyles.info}/>
                    <Typography variant="body1">Notes will be copied into the affected disbursements</Typography>
                </Stack>
                <TextArea label={"Notes"} value={state.notes} disabled={processing} onChange={v => set({ notes: v })} rows={5} />
            </div>
            <div className={styles.modalButtons}>
                <Button variant={"outlined"} disabled={processing} startIcon={<IconCircleX/>} onClick={onCancel}>Cancel</Button>
                <Button variant={"contained"} disabled={processing} startIcon={<IconFileCheck/>} onClick={approve}>Approve</Button>
            </div>
        </Grid>
    );
}
const ApprovedInterimReview: (props: { value: Application, onClose: () => void }) => ReactNode = ({value, onClose}) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(true);
  const {loading, item: client} = useSelector(s => s.client);

  useEffect(() => {
    if (!value.clientCode) return;

    dispatch(getClient(value.clientCode!)).then(() => setPending(false));
  }, [dispatch, value.clientCode]);

  return (
    <Grid container className={styles.modalContainer}>
      {loading || pending || !value.approvedProgram ? <PpulusLoader/> : <>
        <Typography variant={"h1"}>Recipient File Updated </Typography>
        <p className={styles.modalText}>
          {client && `Recipient file with unique identifier ${client.code} (${client.primaryContact.displayName}) has been updated based on this approved interim review. Disbursements after `}
          {DateDisplay.Long(value.approvedProgram.startDate)} have been updated accordingly.
        </p>
        <ButtonGroup className={styles.modalButtons}>
          <Button variant={"contained"} onClick={onClose}>Close</Button>
        </ButtonGroup>
      </>}
    </Grid>
  );
};
export {
    ApproveInterimReview, ApprovedInterimReview
};
