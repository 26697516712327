import styles from "styles/client.module.scss";
import emailStyles from "styles/emailPreview.module.scss";
import gridStyles from "styles/gridTable.module.scss";

import {Button, Card, Grid, Modal} from "@mui/material";
import React, {useEffect, useState} from "react";
import { fullWidth, SentEmail } from "library";
import {useDispatch, useSelector} from "store";
import {getEmails} from "store/slices";
import { PpulusColumn } from "types/grid";
import EmailRow from "library/EmailRow";
import GridTable, { GridTableProps } from "components/GridTable";

type ClientEmailsProps = {
	clientCode: string | undefined;
}

const ClientEmails = ({clientCode}: ClientEmailsProps) => {
	const dispatch = useDispatch();
	const {loading, items} = useSelector(s => s.clientEmails);

	useEffect(() => {
		if (!clientCode) return;

		dispatch(getEmails(clientCode));
	}, [clientCode, dispatch]);
	
	return (
		<EmailGrid loading={loading} items={items} exportFileName={"ClientEmailList"} />
	);
};

type EmailGridProps = {
	loading: boolean;
	items: SentEmail[];
	exportFileName: string;
}

const EmailGrid = ({loading, items, exportFileName}: EmailGridProps) => {
	const [emailPreview, setEmailPreview] = useState<SentEmail>();

	const columns: PpulusColumn<SentEmail>[] = [
		...EmailRow.Columns,
		{
			field: null,
			header: null,
			minSize: 200,
			renderCell: row => <Button variant={"text"} className={gridStyles.compactButton} onClick={() => setEmailPreview(row)}>View</Button>
		}
	];

	const gridTableProps: GridTableProps<SentEmail> = {
		exportCsvEnabled: true,
		height: "400px",
		datasource: items,
		count: items.length,
		loading,
		initialPageSize: 15,
		page: 0,
		exportFileNamePrefix: exportFileName,
		defaultFilterValue: EmailRow.Filter,
		columns: columns,
		userFiltered: false,
		onRowDoubleClick: setEmailPreview
	};

	return (
		<Card className={`${styles.content} ${styles.formDetail}`}>
			<Grid container>
				<Grid {...fullWidth}>
					<GridTable {...gridTableProps} />
				</Grid>
			</Grid>
			<Modal open={!!emailPreview} onClose={() => setEmailPreview(undefined)}>
				<Grid container className={`${styles.modalContainer} ${emailStyles.enhancedBackground} ${emailStyles.roundedCorners}`}>
					<div>
						<strong>Subject:</strong> {emailPreview?.subject}
					</div>
					<p dangerouslySetInnerHTML={{__html: emailPreview?.message ?? ""}}/>
				</Grid>
			</Modal>
		</Card>
	);
};

export {
	ClientEmails,
	EmailGrid
};