import styles from "styles/gridTable.module.scss";

import { PpulusColumn } from "types/grid";
import { CitizenStatus, CurrencyDisplay, DateDisplay, ScreeningResult, YesNo } from "library";
import { PpulusFilter } from "library/ppulusFilter";

class ScreeningRow {
	public static Columns: PpulusColumn<ScreeningRow>[] = [
		{
			field: "displayName",
			header: "Name",
			headerStyle: styles.columnHeader,
			renderCell: row => row.displayName,
			filterVariant: "text",
		},
		{
			field: "dateOfBirth",
			header: "Date of Birth",
			renderCell: row => DateDisplay.Standard(row.dateOfBirth)
		},
		{
			field: "householdIncome",
			header: "Household Income",
			renderCell: row => CurrencyDisplay(row.householdIncome)
		},
		{
			field: "citizenship",
			header: "Citizenship",
			minSize: 150,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(CitizenStatus),
			renderCell: row => row.citizenship
		},
		{
			field: "signedLease",
			header: "Has Lease",
			minSize: 150,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(YesNo),
			renderCell: row => <span className={row.signedLease === YesNo.Yes ? styles.success : styles.failure}>{row.signedLease}</span>
		},
		{
			field: "approved",
			header: "Result",
			minSize: 140,
			filterVariant: "select",
			filterSelectOptions: [{value: "true", label: "Screened In"},{value: "false", label: "Screened Out"} ],
			renderCell: row => <span className={row.approved ? styles.success : styles.failure} >{row.approved ? "Screened In" : "Screened Out"}</span>
		}
	];

	constructor(readonly id: string, readonly displayName: string, readonly dateOfBirth: Date, readonly householdIncome: number, readonly citizenship: CitizenStatus, readonly signedLease: YesNo, readonly approved: boolean) {
	}

	static From(screen: ScreeningResult): ScreeningRow {
		return new ScreeningRow(
			screen.id,
			screen.displayName,
			screen.dateOfBirth,
			screen.householdIncome,
			screen.citizenship,
			screen.signedLease,
			screen.approved
		);
	};
}

export {ScreeningRow};