import { DateDisplay, SentEmail } from "library";
import { PpulusColumn, PpulusFilterValue } from "types/grid";

class EmailRow {
	public static Columns: PpulusColumn<SentEmail>[] = [
		{field: "createdOn", header: "Sent", minSize: 180, renderCell: row => DateDisplay.StandardTime(row.createdOn)},
		{field: "subject", header: "Subject", renderCell: row => row.subject},
	];

	public static Filter: PpulusFilterValue = [];

}
export default EmailRow;