import { ApplicantInformation, Application, ApplicationStatus, DateDisplay } from "library";
import { PpulusColumn } from "types/grid";
import { PpulusFilter } from "library/ppulusFilter";

class InterimReviewRow {
	public static Columns: Record<string, PpulusColumn<InterimReviewRow>> = {
		code: {field: "code", header: "Interim Review ID", minSize: 140, renderCell: row => row.code},
		client: {field: "client", header: "Recipient ID", minSize: 120, renderCell: row => row.client},
		firstName: {field: "applicant.firstName", header: "First Name",	minSize: 200, renderCell: row => row.applicant.firstName},
		lastName:	{field: "applicant.lastName", header: "Last Name", minSize: 200, renderCell: row => row.applicant.lastName},
		status: {
			field: "status",
			header: "Status",
			minSize: 200,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(ApplicationStatus),
			renderCell: row => row.status
		},
		primaryAssignee: {field: "primaryAssignee", header: "P. Assignee", minSize: 100, renderCell: row => row.primaryAssignee},
		secondaryAssignee: {field: "secondaryAssignee", header: "S. Assignee", minSize: 100, renderCell: row => row.secondaryAssignee},
		modifiedOn: {field: "modifiedOn", header: "Modified On", minSize: 120, renderCell: row => DateDisplay.Standard(row.modifiedOn)},
		dueDate: {field: "dueDate", header: "Due Date", minSize: 120, renderCell: row => DateDisplay.Standard(row.dueDate)},
		submittedDate: {field: "submittedDate", header: "Date Submitted", minSize: 200,	renderCell: row => row.status === ApplicationStatus.Submitted ? DateDisplay.Long(row.submittedDate) : ""}
	};

	constructor(
		readonly id: string,
		readonly code: string,
		readonly client: string,
		readonly applicant: ApplicantInformation,
		readonly status: ApplicationStatus,
		readonly primaryAssignee: string | undefined,
		readonly secondaryAssignee: string | undefined,
		readonly modifiedOn: Date,
		readonly dueDate?: Date,
		readonly submittedDate?: Date
	) {
	}

	static From(application: Application): InterimReviewRow | undefined {
		return new InterimReviewRow(
			application.id,
			application.code,
			application.clientCode!,
			application.applicant,
			application.status,
			application.assignments.primary?.name,
			application.assignments.secondary?.name,
			application.modifiedOn,
			application.dueDate,
			application.lastStatusUpdateOn
		);
	}
}

export { InterimReviewRow };