import { PpulusColumn } from "types/grid";
import { DateDisplay, Note } from "library";

class NotesRow {
	public static Columns: PpulusColumn<Note>[] = [
		{field: "createdOn", header: "Date", minSize: 180, enableSorting: false, renderCell: row => DateDisplay.StandardTime(row.createdOn)},
		{field: "subject", header: "Title / Subject", enableSorting: false, renderCell: row => row.subject},
		{field: "message", header: "Note", enableSorting: false, renderCell: row => row.message},
		{field: "createdBy", header: "Created By", minSize: 140, enableSorting: false, renderCell: row => row.createdBy},
		{field: "internal", header: "Visibility", minSize: 90, enableSorting: false, renderCell: row => row.internal ? "Internal" : "Public"},
		{field: "isPriority", header: "Priority?", minSize: 80, enableSorting: false, renderCell: row => row.isPriority ? "Yes" : "No"}
	];
}

export default NotesRow;
