import { ApplicantInformation, Application, ApplicationStatus, ApplicationType, DateDisplay } from "library";
import { PpulusColumn } from "types/grid";
import { PpulusFilter } from "library/ppulusFilter";

class AnnualReviewRow {
	public static SimpleColumns: PpulusColumn<AnnualReviewRow>[] = [
		{field: "code", header: "Annual Review #", minSize: 120, enableSorting: false, renderCell: row => row.code},
		{field: "client", header: "Recipient #", minSize: 100, enableSorting: false, renderCell: row => row.client},
		{field: "applicant.displayName", header: "Recipient Name", minSize: 120, enableSorting: false, renderCell: row => row.applicant.displayName},
		{field: "status", header: "Status", minSize: 100, enableSorting: false, renderCell: row => row.status},
		{field: "primaryAssignee", header: "P. Assignee", minSize: 100, enableSorting: false, renderCell: row => row.primaryAssignee},
		{field: "secondaryAssignee", header: "S. Assignee", minSize: 100,enableSorting: false, renderCell: row => row.secondaryAssignee},
		{field: "dueDate", header: "Due Date", minSize: 120, enableSorting: false, renderCell: row => DateDisplay.Standard(row.dueDate)},
	];

	public static Columns: PpulusColumn<AnnualReviewRow>[] = [
		{field: "code", header: "Annual Review #", minSize: 120, renderCell: row => row.code},
		{field: "client", header: "Recipient #", minSize: 120, renderCell: row => row.client},
		{field: "applicant.firstName", header: "First Name", minSize: 200, renderCell: row => row.applicant.firstName},
		{field: "applicant.lastName", header: "Last Name", minSize: 200, renderCell: row => row.applicant.lastName},
		{
			field: "status",
			header: "Status",
			minSize: 120,
			filterVariant: "select",
			filterSelectOptions: PpulusFilter.EnumToDropdownOptions(ApplicationStatus),
			renderCell: row => row.status
		},
		{field: "primaryAssignee", header: "P. Assignee", minSize: 100, renderCell: row => row.primaryAssignee},
		{field: "secondaryAssignee", header: "S. Assignee", minSize: 100, renderCell: row => row.secondaryAssignee},
		{field: "modifiedOn", header: "Modified On", minSize: 120, renderCell: row => DateDisplay.Standard(row.modifiedOn)},
		{field: "dueDate", header: "Due Date", minSize: 120, renderCell: row => DateDisplay.Standard(row.dueDate)},
		{field: "submittedDate", header: "Date Submitted", minSize: 200, renderCell: row => row.status === ApplicationStatus.Submitted ? DateDisplay.Long(row.submittedDate) : ""}
	];

	constructor(readonly id: string,
							readonly type: ApplicationType,
							readonly code: string,
							readonly client: string,
							readonly applicant: ApplicantInformation,
							readonly status: ApplicationStatus,
							readonly primaryAssignee: string,
							readonly secondaryAssignee: string,
							readonly modifiedOn: Date,
							readonly dueDate?: Date,
							readonly submittedDate?: Date) {
	}

	static From(application: Application): AnnualReviewRow | undefined {
		return application.type === ApplicationType.AnnualReview
			? new AnnualReviewRow(
				application.id,
				application.type,
				application.code,
				application.clientCode!,
				application.applicant,
				application.status,
				application.assignments.primary?.name ?? "",
				application.assignments.secondary?.name ?? "",
				application.modifiedOn,
				application.dueDate,
				application.lastStatusUpdateOn)
			: undefined;
	}
}

export {AnnualReviewRow};