import { PpulusLoader } from "components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { getApplicationEmails } from "store/slices/applicationEmails";
import { EmailGrid } from "views/client/Client.Emails";

type ApplicationEmailsProps = {
	applicationCode: string | undefined;
}

const ApplicationEmails = ({applicationCode}: ApplicationEmailsProps) => {
	const dispatch = useDispatch();
	const {loading, items} = useSelector(s => s.applicationEmails);

	useEffect(() => {
		if (!applicationCode) return;

		dispatch(getApplicationEmails(applicationCode));
	}, [applicationCode, dispatch]);

	if (loading || !applicationCode)
		return <PpulusLoader/>;

	return (
		<EmailGrid loading={loading} items={items} exportFileName={"ApplicationEmailList"} />
	);
};

export {
	ApplicationEmails
};
