// third-party
import {FormattedMessage} from "react-intl";

// assets
import {IconAdjustments, IconAffiliate, IconBallpen, IconBrandCashapp, IconBug, IconCalendarTime, IconChecklist, IconFileAnalytics, IconFriends, IconHome2, IconKey, IconLamp2, IconReportAnalytics, IconSearch} from "@tabler/icons";

import {NavItemType} from "types";
import {PpulusRoutes} from "library";

// constant
const icons = {
    IconKey,
    IconBug,
    IconAdjustments,
    IconHome2,
    IconReportAnalytics,
    IconBallpen,
    IconFriends,
    IconFileAnalytics,
    IconLamp2,
    IconBrandCashapp,
    IconAffiliate,
    IconCalendarTime,
    IconChecklist,
    IconSearch
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages: NavItemType = {
    id: 'menu-title',
    title: <FormattedMessage id="menu-title" />,
    caption: <FormattedMessage id="menu-caption" />,
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'welcome-page',
            title: <FormattedMessage id="welcome" />,
            type: 'item',
            url: PpulusRoutes.Dashboard,
            icon: icons.IconHome2,
            breadcrumbs: false
        },
        {
            id: "global-search",
            title: <FormattedMessage id={"global-search"} />,
            type: "none",
            url: PpulusRoutes.GlobalSearch
        },
        {
            id: 'intake',
            title: <FormattedMessage id="intake" />,
            type: 'collapse',
            icon: icons.IconBallpen,
            children: [
                {
                    id: 'screening',
                    title: <FormattedMessage id={"screening"} />,
                    type: 'item',
                    url: '/pages/screening',
                    target: false
                },
                {
                    id: 'my-applications',
                    title: <FormattedMessage id="my-applications" />,
                    type: 'item',
                    url: PpulusRoutes.MyApplications,
                    target: false
                },
                {
                    id: 'submitted-applications',
                    title: <FormattedMessage id={"submitted-applications"} />,
                    type: 'item',
                    url: '/pages/applications/submitted',
                    target: false
                },
                {
                    id: "pending-approval-applications",
                    title: <FormattedMessage id={"pending-approval-applications"} />,
                    type: "item",
                    url: "/pages/applications/pendingApproval",
                    target: false
                },
                {
                    id: "eligible-applications",
                    title: <FormattedMessage id={"eligible-applications"}/>,
                    type: "item",
                    url: "/pages/applications/eligible",
                    target: false
                },
                {
                    id: "onoffer-applications",
                    title: <FormattedMessage id={"onoffer-applications"}/>,
                    type: "item",
                    url: "/pages/applications/onoffer",
                    target: false
                },
                {
                    id: "accepted-applications",
                    title: <FormattedMessage id={"accepted-applications"}/>,
                    type: "item",
                    url: "/pages/applications/accepted",
                    target: false
                },
                {
                    id: 'applications',
                    title: <FormattedMessage id="applications" />,
                    type: 'item',
                    url: PpulusRoutes.AllApplications,
                    target: false
                }
            ]
        },
        {
            id: 'receipient-management',
            title: <FormattedMessage id="recipient-management" />,
            type: 'collapse',
            icon: icons.IconFriends,
            children: [
                {
                    id: 'my-recipients',
                    title: <FormattedMessage id="my-recipients" />,
                    type: 'item',
                    url: PpulusRoutes.MyClients,
                    target: false
                },
                {
                    id: 'recipients',
                    title: <FormattedMessage id="recipients" />,
                    type: 'item',
                    url: PpulusRoutes.Clients,
                    target: false
                }
            ]
        },
        {
            id: 'disbursements',
            title: <FormattedMessage id="disbursements" />,
            type: 'collapse',
            icon: icons.IconBrandCashapp,
            children: [
                {
                    id: 'disbursements-generation',
                    title: <FormattedMessage id="disbursements-generation" />,
                    type: 'item',
                    url: '/pages/disbursements/generate',
                    target: false
                },
                {
                    id: 'disbursements-cheques',
                    title: <FormattedMessage id={"disbursements-cheques"}/>,
                    type: "item",
                    url: "/pages/disbursements/cheques",
                    target: false
                },
                {
                    id: 'disbursements-files',
                    title: <FormattedMessage id="disbursements-files"/>,
                    type: 'item',
                    url: '/pages/disbursements/files',
                    target: false
                },
                {
                    id: 'disbursements',
                    title: <FormattedMessage id="disbursements-list" />,
                    type: 'item',
                    url: PpulusRoutes.Disbursements,
                    target: false
                }
            ]
        },
        {
            id: "interim-reviews",
            title: <FormattedMessage id={"interim-reviews"}/>,
            type: "collapse",
            icon: icons.IconChecklist,
            children: [
                {
                    id: "interim-reviews-review",
                    title: <FormattedMessage id={"interim-reviews-review"}/>,
                    type: "item",
                    url: `${PpulusRoutes.InterimReviews}/review`,
                    target: false
                },
                {
                    id: "interim-reviews-all",
                    title: <FormattedMessage id={"interim-reviews-all"}/>,
                    type: "item",
                    url: PpulusRoutes.InterimReviews,
                    target: false
                }
            ]
        },
        {
            id: 'annual-reviews',
            title: <FormattedMessage id="annual-reviews" />,
            type: 'collapse',
            icon: icons.IconChecklist,
            children: [
                {
                    id: "annual-reviews-dashboard",
                    title: <FormattedMessage id={"annual-reviews-dashboard"}/>,
                    type: "item",
                    url: `${PpulusRoutes.AnnualReviews}/dashboard`,
                    target: false
                },
                {
                    id: 'my-annual-reviews',
                    title: <FormattedMessage id="my-annual-reviews" />,
                    type: 'item',
                    url: PpulusRoutes.MyAnnualReviews,
                    target: false
                },
                {
                    id: 'annual-reviews-review',
                    title: <FormattedMessage id="annual-reviews-review"/>,
                    type: 'item',
                    url: `${PpulusRoutes.AnnualReviews}/review`,
                    target: false
                },
                {
                    id: 'annual-reviews',
                    title: <FormattedMessage id="annual-reviews-list" />,
                    type: 'item',
                    url: PpulusRoutes.AnnualReviews,
                    target: false
                }
            ]
        }
    ]
};

export default pages;