import styles from "styles/client.module.scss";
import gridStyles from "styles/gridTable.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { Application, ApplicationContent, ApplicationType } from "library";
import {Button, Typography} from "@mui/material";
import {useDispatch, useSelector} from "store";
import {generateAnnualReview, generateInterimReview, getApplications} from "store/slices";
import {useNavigate} from "react-router-dom";
import GridTable, { GridTableProps } from "components/GridTable";
import { ClientAnnualReviewRow, ClientApplicationsRow } from "../application/ClientApplicationsRow";
import { PpulusColumn } from "types/grid";

type ClientApplicationsProps = {
    clientCode: string | undefined;
    applicationType: ApplicationType;
		readonly: boolean;
}

const ClientApplications = ({clientCode, applicationType, readonly}: ClientApplicationsProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [applications, setApplications] = useState<Application[]>([]);
	const {loading} = useSelector(s => s.applications);
	const {item: client} = useSelector(s => s.client);
	const isAnnualReview = [ApplicationType.AnnualReview, ApplicationType.InterimReview].includes(applicationType);

	const loadApplication = useCallback((id: string) => navigate(`/pages/${ApplicationContent[applicationType].path}/${id}`), [navigate, applicationType]);
	
	useEffect(() => {
		setApplications([]);
		dispatch(getApplications({
			filter: {
				items: [
					{columnField: "type", value: applicationType, operatorValue: "equals"},
					{columnField: "clientCode", value: clientCode, operatorValue: "equals"}
				]
			},
			pageSize: 0
		}))
			.unwrap()
			.then(({items}) => setApplications(items));
	}, [dispatch, clientCode, applicationType]);

	const generate = () => {
		if (!clientCode) return;
		const applicationTypeAction: Record<ApplicationType, any> = {
			[ApplicationType.AnnualReview]: generateAnnualReview(clientCode),
			[ApplicationType.InterimReview]: generateInterimReview(clientCode),
			[ApplicationType.Standard]: undefined,
		};
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		applicationTypeAction[applicationType] && dispatch(applicationTypeAction[applicationType]).unwrap().then(
			(application: Application) => !!application?.id && navigate(`/pages/${ApplicationContent[applicationType].path}/${application.id}`)
		);
	};

	const canGenerate = !readonly && (applicationType !== ApplicationType.InterimReview || client?.programs.some(p => p.isActive));
	const fromMap = isAnnualReview ? ClientAnnualReviewRow.From : ClientApplicationsRow.From;
	
	const columns = [
		...ClientApplicationsRow.Columns,
		{field: null, renderCell: row => <Button className={gridStyles.compact} onClick={() => loadApplication(row.id)} >View</Button> } as PpulusColumn<ClientApplicationsRow>,
	]
	
	const gridTableProps: GridTableProps<ClientApplicationsRow> = {
		exportCsvEnabled: true,
		datasource: applications.map(fromMap),
		count: applications.length,
		loading,
		initialPageSize: 15,
		page: 0,
		exportFileNamePrefix: "ApplicationList",
		defaultFilterValue: ClientApplicationsRow.Filter,
		columns: columns,
		userFiltered: false,
		isFilterable: false,
		onRowDoubleClick: row => loadApplication(row.id), 
	};

	return (
		<div className={styles.contentBg}>
			{isAnnualReview
				? <div className={styles.title}>
					<Typography variant={"h3"}>{ApplicationContent[applicationType].listHeading}</Typography>
					{canGenerate && <Button variant={"contained"} color={"primary"} disabled={loading} onClick={() => generate()}>GENERATE {ApplicationContent[applicationType].heading.toUpperCase()}</Button>}
				</div>
				: <Typography variant={"h3"}>{ApplicationContent[applicationType].listHeading}</Typography>}
			<GridTable {...gridTableProps} />
		</div>
	);
};

export {
	ClientApplications
};