import {createSlice} from "@reduxjs/toolkit";
import {Application, EmailTemplate, ProgramApproval} from "library";
import {createAppAsyncThunk} from "./asyncThunk";
import agent from "api/agent";

interface IEmailKey {
    emailTemplate: EmailTemplate;
    application?: Partial<Application>;
    program?: ProgramApproval;
    override?: boolean;
}

const getEmail = createAppAsyncThunk("emails/get", async (value: IEmailKey, {getState, signal}) => {
    const {
        application: {item: application},
        client: {item: client},
        emails: {items: emails}
    } = getState();
    const email = value.emailTemplate;
    const existingEmail = emails[email];
    const alreadyLoadedEmail = (compare: (v: any) => boolean) => !!existingEmail && compare(existingEmail.key) ? existingEmail.value : undefined;

    switch (email) {
        case EmailTemplate.SubmitApplication:
        case EmailTemplate.RequestDocuments:
        case EmailTemplate.RejectApplication:
        case EmailTemplate.CancelApplication:
        case EmailTemplate.CancelInterimReview:
        case EmailTemplate.EligibleApplication:
        case EmailTemplate.OfferApplication:
        case EmailTemplate.ApproveApplication:
        case EmailTemplate.ApproveReview:
        case EmailTemplate.ApproveInterimReview:
            const emailApplication = new Application({...(!!value.application ? {...application!, ...value.application} : application!), ...{notes: []}});
            const cachedTemplate = !value.override ? alreadyLoadedEmail(v => v === application!.code) : undefined;

            return {[email]: {key: application!.code, value: cachedTemplate ?? await agent.Applications.getEmail(email, emailApplication!, signal)}};
        case EmailTemplate.ActivateClient:
        case EmailTemplate.DeactivateClient:
        case EmailTemplate.SuspendClient:
            return {[email]: {key: client!.code, value: alreadyLoadedEmail(v => v === client!.code) ?? await agent.Clients.getEmail(client!.code, email)}};
        case EmailTemplate.ExtendClient:
        case EmailTemplate.OnNoticeClient:
            return {[email]: {key: client!.code, value: await agent.Clients.getEmail(client!.code, email, value.program)}};
    }
});

const initialState: { loading: boolean, items: Record<EmailTemplate, { key: any, value: { subject: string, body: string } } | undefined> } = {
    loading: false,
    items: Object.create(Object.keys(EmailTemplate).map(v => ({[v]: undefined})))
};

const emails = createSlice({
    name: "emails",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEmail.pending, (state) => ({...state, loading: true}));
        builder.addCase(getEmail.fulfilled, (state, {payload}) => ({loading: false, items: {...state.items, ...payload}}));
    }
});

export default emails.reducer;
export {type IEmailKey, getEmail};