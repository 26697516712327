import styles from "styles/gridTable.module.scss";

import {ReactNode, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "store";
import {Grid} from "@mui/material";
import {getDisbursements, initialFilter, setDisbursementsFilter, setDisbursementsPage, setDisbursementsSort} from "store/slices/disbursements";
import {DisbursementRow} from "./Disbursement.Row";
import GridTable, { GridTableProps } from "components/GridTable";
import { PpulusColumn, PpulusGridFilterModel, PpulusGridSortItem } from "types/grid";

const DisbursementsList: () => ReactNode = () => {
	const dispatch = useDispatch();
	const {items: disbursements, loading, count, page, pageSize, sort} = useSelector(state => state.disbursements);
	useEffect(() => {
		dispatch(getDisbursements({pageSize: 15, page: 0, filter: initialFilter}));
	}, [dispatch]);

	const onPage = useCallback((page: number, pageSize: number) => {
		dispatch(setDisbursementsPage({page, pageSize}));
	}, [dispatch]);

	const onSort = useCallback((model: PpulusGridSortItem) => {
		dispatch(setDisbursementsSort(model));
	}, [dispatch]);

	const onFilter = useCallback((model: PpulusGridFilterModel) => {
		dispatch(setDisbursementsFilter({items: model.items}));
	}, [dispatch]);

	const columns: PpulusColumn<DisbursementRow>[] = [
		...Object.values(DisbursementRow.Columns),
	];
	
	const gridTableProps: GridTableProps<DisbursementRow> = {
		exportCsvEnabled: false,
		datasource: disbursements.map(DisbursementRow.From),
		count,
		loading,
		initialPageSize: pageSize ?? 15,
		page: page ?? 0,
		exportFileNamePrefix: "DisbursementsList",
		onFilter: onFilter,
		onPage: onPage,
		onSort: onSort,
		defaultFilterValue: [],
		columns: columns,
		userFiltered: false,
		rowClassName: styles.row,
		sort: sort,
		pageSizeOptionsEnabled: false
	};

	return (
		<Grid container columnSpacing={4}>
			<Grid item width={"100%"}>
				<GridTable {...gridTableProps} />
			</Grid>
		</Grid>
	);
};

export {
	DisbursementRow,
	DisbursementsList
};