import {BankInfo, CurrencyDisplay, DateDisplay, Payment, PaymentStatus} from "library";
import {createElement, Fragment} from "react";
import {IconCheck} from "@tabler/icons";
import { PpulusColumn } from "types/grid";

class ChequeRow {
	public static Columns: Partial<Record<keyof ChequeRow, PpulusColumn<ChequeRow>>> = {
		clientCode: {field: "clientCode", header: "Recipient #", minSize: 130, renderCell: row => row.clientCode},
		clientName: {field: "clientName", header: "Recipient", minSize: 200, renderCell: row => `${row.clientName}${row.bankDetails.isOrganization ? " (Org)" : ""}`},
		address: {field: "address", header: "Address", minSize: 250, renderCell: row => row.address},
		program: {field: "program", header: "Program", maxSize: 80, renderCell: row => row.program},
		amount: {field: "amount", header: "Amount", minSize: 140, renderCell: row => CurrencyDisplay(row.amount)},
		status: {field: "status", header: "Status", minSize: 140, renderCell: row => row.status},
		paymentDateDisplay: {field: "paymentDateDisplay", header: "Payment Date", minSize: 150, renderCell: row => DateDisplay.Standard(row.paymentDateDisplay)},
		adjustmentMade: {field: "adjustmentMade", header: "Adjusted", minSize: 100, renderCell: row => row.adjustmentMade ? createElement(IconCheck, {color: "green"}) : createElement(Fragment)}
	};

	constructor(readonly id: string,
							readonly clientCode: string,
							readonly clientName: string,
							readonly bankDetails: BankInfo,
							readonly address: string,
							readonly program: string,
							readonly amount: number,
							readonly status: string,
							readonly paymentDateDisplay: Date | undefined,
							readonly adjustmentMade: boolean,
							readonly payment: Payment) {
	}

	static From(payment: Payment): ChequeRow {
		return new ChequeRow(
			payment.id ?? "",
			payment.clientCode,
			payment.clientName,
			payment.bankDetails,
			payment.address,
			payment.program,
			payment.amount,
			payment.status,
			payment.status === PaymentStatus.Paid ? payment.scheduledDate : undefined,
			payment.adjustmentMade,
			payment
		);
	}
}

export {ChequeRow};