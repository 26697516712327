import {FileInfo, IFileInfo} from "library";

interface IDocumentRequest {
	required: boolean;
	files: IFileInfo[];
}

class DocumentRequest implements IDocumentRequest {
	readonly required: boolean;
	readonly files: FileInfo[];

	constructor(from?: Partial<DocumentRequest>) {
		const mapFileInfo = (f: IFileInfo) => new FileInfo({name: f.name, size: f.size, type: f.type, lastModified: f.lastModified});

		this.required = from?.required ?? false;
		this.files = from?.files?.map(mapFileInfo) ?? [];
	}
}

interface IDocuments {
	rentProof: DocumentRequest;
	eftDetails: DocumentRequest;
	incomeDocuments: DocumentRequest;
	statusInCanada: DocumentRequest;
	offerAgreement: DocumentRequest;
	other: DocumentRequest;
}

type Document = {
	file: FileInfo;
	documentType: keyof IDocuments;
}

const DocumentTypeMap: Record<keyof IDocuments, string> = {
	rentProof: "Rent Proof",
	eftDetails: "Banking (EFT) Details",
	incomeDocuments: "Income Document(s)",
	statusInCanada: "Status in Canada",
	offerAgreement: "Offer Agreement",
	other: "Other",
};

interface IBankInfo {
	chequePayments: boolean;
	isOrganization: boolean;
	holderName: string;
	transit: string;
	institution: string;
	account: string;
}

class BankInfo implements IBankInfo {
	chequePayments: boolean;
	isOrganization: boolean;
	holderName: string;
	transit: string;
	institution: string;
	account: string;

	constructor(from?: Partial<IBankInfo>) {
		this.chequePayments = from?.chequePayments ?? false;
		this.isOrganization = from?.isOrganization ?? false;
		this.holderName = from?.holderName ?? "";
		this.transit = from?.transit ?? "";
		this.institution = from?.institution ?? "";
		this.account = from?.account ?? "";
	}

	get canProcess(): boolean {
		return this.chequePayments || (!!this.transit && !!this.institution && !!this.account);
	}
}

interface IApplicationInstructions {
	bankInfo?: BankInfo;
	specialMessage: string;
}

class ApplicationDocuments implements IDocuments {
	[key: string]: any;

	readonly bankInfo: BankInfo;
	readonly rentProof: DocumentRequest;
	readonly incomeDocuments: DocumentRequest;
	readonly eftDetails: DocumentRequest;
	readonly other: DocumentRequest;
	readonly statusInCanada: DocumentRequest;
	readonly offerAgreement: DocumentRequest;
	readonly specialMessage: string;

	constructor(from?: Partial<IDocuments> & Partial<IApplicationInstructions>) {
		this.bankInfo = new BankInfo(from?.bankInfo);
		this.rentProof = new DocumentRequest(from?.rentProof);
		this.incomeDocuments = new DocumentRequest(from?.incomeDocuments);
		this.eftDetails = new DocumentRequest(from?.eftDetails);
		this.other = new DocumentRequest(from?.other);
		this.statusInCanada = new DocumentRequest(from?.statusInCanada);
		this.offerAgreement = new DocumentRequest(from?.offerAgreement);
		this.specialMessage = from?.specialMessage ?? "";
	}

	get documentCount(): number {
		return this.rentProof.files.length
			+ this.incomeDocuments.files.length
			+ this.eftDetails.files.length
			+ this.other.files.length
			+ this.statusInCanada.files.length
			+ this.offerAgreement.files.length;
	}

	get documents(): Document[] {
		const asDocument = (documentType: keyof IDocuments) => (file: IFileInfo) => ({documentType, file: new FileInfo(file)});
		return [
			...this.rentProof.files.map(asDocument("rentProof")),
			...this.incomeDocuments.files.map(asDocument("incomeDocuments")),
			...this.eftDetails.files.map(asDocument("eftDetails")),
			...this.other.files.map(asDocument("other")),
			...this.statusInCanada.files.map(asDocument("statusInCanada")),
			...this.offerAgreement.files.map(asDocument("offerAgreement"))
		];
	}
}

export type {IDocuments, Document};
export {ApplicationDocuments, DocumentTypeMap, DocumentRequest, BankInfo};